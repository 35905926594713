import { Field, Form, Formik } from 'formik';
import React from 'react';
import { LocationsJobRolesField } from '../../../../components/LocationsJobRolesField';
import { SelectField } from '../../../../components/SelectField/SelectField';
import { fieldErrorClass } from '../../../../constants';
import { useGetProfileQuery } from '../../../../store/apis/currentUserApi';
import { useInviteCustomerUserMutation } from '../../../../store/apis/invitationApi';
import validators from '../../../../utils/validators';

export const InviteForm = ( { closeModal } ) => {
  const { data: profile } = useGetProfileQuery();
  const [ inviteUser, { isError, isSuccess } ] = useInviteCustomerUserMutation();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        class: 'admin',
        LocationIds: [],
        JobRoleIds: []
      }}
      onSubmit={( values, { setSubmitting } ) => {
        inviteUser( { ...values, CompanyAccountId: profile?.profile.CompanyAccountId } )
          .unwrap()
          .then( () => {
            closeModal();
          } )
          .finally( () => setSubmitting( false ) );
      }}
      validateOnMount={true}
    >
      {( {
        values,
        handleChange,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        isValid
      } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='firstName'>First name*</label>
              <Field
                className={`form-control ${
                  errors.firstName && touched.firstName && fieldErrorClass
                }`}
                type='text'
                id='firstName'
                name='firstName'
                validate={validators.required}
                value={values.firstName}
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='lastName'>Last name*</label>
              <Field
                className={`form-control ${
                  errors.lastName && touched.lastName && fieldErrorClass
                }`}
                type='text'
                id='lastName'
                name='lastName'
                validate={validators.required}
                value={values.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='phone'>Phone</label>
              <Field
                className={`form-control ${
                  errors.phone && touched.phone && fieldErrorClass
                }`}
                type='phone'
                id='phone'
                name='phone'
                value={values.phone}
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='email'>Email*</label>
              <Field
                className={`form-control ${
                  errors.email && touched.email && fieldErrorClass
                }`}
                type='email'
                id='email'
                name='email'
                validate={validators.required}
                value={values.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='select-box w-100 mb-3'>
            <label>
              <span>User role*</span>
              <SelectField
                name='class'
                options={[
                  { value: 'admin', label: 'Company Admin' },
                  { value: 'user', label: 'Company User' }
                ]}
                containerStyle={{ maxWidth: 'auto', margin: 0 }}
              />
            </label>
          </div>

          {values.class !== 'admin' && <LocationsJobRolesField />}
          <div className='form-footer'>
            <div className='form-row mt-4 mb-2'>
              {isSuccess && (
                <span
                  className='text-danger'
                  style={{ fontSize: 12 }}
                >
                  Invite sent successfully
                </span>
              )}
              {isError && (
                <span
                  className='text-danger'
                  style={{ fontSize: 12 }}
                >
                  Failed to send invite
                </span>
              )}
              <button
                className='btn btn--green'
                type='submit'
                disabled={isSubmitting || !isValid || isSuccess || isError}
              >
                Send
              </button>
            </div>
            <div className='form-row mt-2 mb-0'>
              <button
                className='btn btn--default'
                onClick={() => closeModal()}
                type='button'
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
