import { DateTime } from 'luxon';
import { OperationBadge } from '../screens/OperationsScreen/components';

export const getJobLogValues = ( event, eventStartTimeAsDefault ) => ( {
  Employee_Comment: event?.JobLog?.Employee_Comment,
  Employee_Rating: event?.JobLog?.Employee_Rating,
  Employee_Start: event?.JobLog?.Employee_Start
    ? new Date( event?.JobLog?.Employee_Start )
    : eventStartTimeAsDefault
    ? new Date( event.start )
    : undefined,
  Employee_End: event?.JobLog?.Employee_Start
    ? new Date( event?.JobLog?.Employee_End )
    : eventStartTimeAsDefault
    ? new Date( event.end )
    : undefined,
  Employee_LunchBreak: event?.JobLog?.Employee_LunchBreak,
  Customer_Rating: event?.JobLog?.Customer_Rating,
  Customer_Comment: event?.JobLog?.Customer_Comment
} );

export const concat = ( ...names ) => names.join( ' ' ).trim();
export const roundHalf = num => Math.floor( num / 0.5 ) * 0.5;

export const getAvailabilityType = availability => {
  const start = DateTime.fromISO( availability.start );
  const end = DateTime.fromISO( availability.end );
  const diff = end.diff( start, 'hours' ).hours;
  if ( diff < 18 ) {
    return 'partly';
  } else {
    return availability.type;
  }
};

export const getEmployeeWeekTiles = ( week, eventsByDate, employee ) =>
  Array( 7 )
    .fill()
    .map( ( _, i ) => {
      const day = week.plus( { days: i } ).toISODate();
      const events = eventsByDate[day]?.filter( event => event.status !== 'open' );
      if ( events?.length ) {
        return {
          content: (
            <OperationBadge
              events={events}
              employee={employee}
            />
          )
        };
      }
      return {
        content: undefined
      };
    } );

export const getEmployeeWorkingHours = ( acceptedOffers, jobRoleId ) => {
  let sum = 0;
  for ( const offer of acceptedOffers ) {
    if ( jobRoleId !== 'all' && +offer.JobEvent.JobRoleId !== +jobRoleId ) continue;
    sum += DateTime.fromISO( offer.JobEvent.end ).diff(
      DateTime.fromISO( offer.JobEvent.start ),
      'hours'
    ).hours;
  }
  return roundHalf( sum );
};
