import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetLocationsQuery } from '../../../../store/apis/locationsApi';
import { concat } from '../../../../utils/utils';

export const LocationField = ( { className } ) => {
  const location = useLocation();

  const { values, setFieldValue } = useFormikContext();

  const costCenterId = values.costCenter;

  const { data: locations } = useGetLocationsQuery();

  const options = useMemo( () => {
    if ( !costCenterId || !locations ) return;
    return locations
      .filter( location => location.CostCenterId === costCenterId )
      .map( location => ( { label: location.name, value: location.id } ) );
  }, [ costCenterId, locations ] );

  const onChange = e => {
    setFieldValue( 'location', e.target.value );
    setFieldValue( 'jobRole', '' );
  };

  useEffect( () => {
    if ( location.state?.locationId ) {
      setFieldValue( 'location', location.state.locationId );
    }
  }, [ location.state ] );

  return (
    <div className={concat( 'form-row', 'w-100', className )}>
      <div className='select-box'>
        <label htmlFor='location'>Location</label>
        <Field
          as='select'
          id={'location'}
          name={'location'}
          className='form-control'
          value={values['location']}
          onChange={onChange}
          disabled={!options || options.length === 0}
        >
          <option
            value=''
            label={'Select location'}
            disabled
            hidden
          />
          {options?.map( option => (
            <option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ) )}
        </Field>
      </div>
    </div>
  );
};
