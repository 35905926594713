import { useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../../components/Card';
import Legends from '../../../components/Legend';
import { Spinner } from '../../../components/Spinner';
import { useGetJobEventsPolling } from '../../../hooks';
import { eventListingActions } from '../../../store/slices';
import { PieChartBgColor } from '../../../theme';
import { filterEventsByStatus } from '../../../utils/filters/eventsFilters';
import { currentEventsSelectOptions } from '../data';

export const CurrentEvents = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ filter, setFilter ] = useState( currentEventsSelectOptions[0] );

  const { data: jobEvents, isLoading } = useGetJobEventsPolling( {
    start: filter.start,
    end: filter.end
  } );

  const openEvents = useMemo(
    () => filterEventsByStatus( jobEvents || [], [ 'open', 'unprocessed' ] ),
    [ jobEvents ]
  );
  const filledEvents = useMemo(
    () => filterEventsByStatus( jobEvents || [], [ 'filled' ] ),
    [ jobEvents ]
  );

  const setEventListingFilters = ( start, end, status ) => () => {
    dispatch( eventListingActions.setStartDate( start ) );
    dispatch( eventListingActions.setEndDate( end ) );
    dispatch( eventListingActions.setStatusFilter( status ) );
  };

  const goToListingWithFilters = ( start, end, status ) => () => {
    setEventListingFilters( start, end, status )();
    history.push( '/event-listing' );
  };

  const currentEventsChartData = useMemo(
    () => [
      {
        title: 'Open',
        value: openEvents.length,
        color: '#818CF8',
        onClick: goToListingWithFilters( filter.start, filter.end, {
          label: 'Open',
          value: 'open'
        } )
      },
      {
        title: 'Filled',
        value: filledEvents.length,
        color: '#4ADE80',
        onClick: goToListingWithFilters( filter.start, filter.end, {
          label: 'Filled',
          value: 'filled'
        } )
      },
      {
        title: 'Total',
        value: openEvents.length + filledEvents.length,
        exclude: true
      }
    ],
    [ jobEvents, filter ]
  );

  const percentage = useMemo(
    () =>
      Math.round( ( filledEvents.length / ( openEvents.length + filledEvents.length ) ) * 100 ),
    [ openEvents, filledEvents ]
  );

  return (
    <Card
      title='Current events'
      selectFilterState={filter}
      setSelectFilterState={setFilter}
      selectOptions={currentEventsSelectOptions}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='card-body'>
            <div className='graphic'>
              <PieChart
                data={currentEventsChartData.filter( item => !item.exclude )}
                lineWidth={50}
                background={PieChartBgColor}
                animate={true}
              />

              <div className='custom-chart'>
                <b className='custom-chart__count'>{percentage}%</b>
                <span className='custom-chart__desc'>Filled</span>
              </div>
            </div>
            <Legends units={currentEventsChartData} />
          </div>
          <div className='card-footer'>
            <Link
              className='btn btn--light-green btn--block'
              to='/event-listing'
              onClick={setEventListingFilters( filter.start, filter.end )}
            >
              Show all
            </Link>
          </div>
        </>
      )}
    </Card>
  );
};
