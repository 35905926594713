import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactComponent as SickLeaveIcon } from '../../assets/images/red-plus.svg';
import { useGetAllEmployeesQuery } from '../../store/apis/employeesApi';
import { EmployeeHoverBox } from './EmployeeHoverBox';
import './EventItem.sass';

export const EventItem = ( { event } ) => {
  const { url } = useRouteMatch();

  const { data: employees } = useGetAllEmployeesQuery(
    event.status === 'filled'
      ? {
          filters: { statuses: [ 'active' ] },
          includes: {
            AcceptedOffers: {
              start: DateTime.now().minus( { days: 180 } ).startOf( 'day' ).toJSDate(),
              end: DateTime.now().endOf( 'day' ).toJSDate()
            }
          }
        }
      : skipToken
  );

  const employee = useMemo( () => {
    if ( !employees ) return;
    return employees.find( employee => employee.id === event.JobOffer?.AcceptedEmployeeId );
  }, [ employees, event ] );

  const className = useMemo( () => {
    const names = [ 'event-listing-item' ];
    if ( event.lockedAt ) names.push( 'locked-editing' );
    return names.join( ' ' );
  }, [ event.lockedAt ] );

  const duration = useMemo( () => {
    const start = DateTime.fromISO( event.start );
    const end = DateTime.fromISO( event.end );
    return Math.floor( end.diff( start, 'hours' ).hours );
  }, [ event ] );

  const timeString = useMemo( () => {
    const start = DateTime.fromISO( event.start );
    const end = DateTime.fromISO( event.end );
    return `${start.toFormat( 'dd.LL.' )} ${start.toFormat( 'HH:mm' )}-${end.toFormat(
      'HH:mm'
    )} (${duration})`;
  }, [ event, duration ] );

  const employeeText = useMemo( () => {
    if ( event.status === 'filled' ) {
      const employee = event.JobOffer.AcceptedEmployee;
      return [ employee.firstName, employee.lastName ].join( ' ' );
    } else if ( event.JobOffer?.ReceivedEmployees?.length ) {
      return (
        <span>
          Tarjottu <b>( {event.JobOffer.ReceivedEmployees.length} )</b>
        </span>
      );
    } else {
      return 0;
    }
  }, [ event ] );

  return (
    <tr
      key={event.id}
      className={className}
    >
      <td className='link-in text-bold sick-leve-icon-container'>
        <NavLink to={`${url}/${event.id}`}>
          {!!event.lockedAt && <span className='locked-icon' />}
          {timeString}
          {event.sickLeaveReplacement && <SickLeaveIcon className='sick-leave-icon' />}
        </NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{event.JobRole.Location.name}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{event.JobRole.name}</NavLink>
      </td>
      <td className={'link-in'}>
        <NavLink
          to={`${url}/${event.id}`}
          className={event.status === 'filled' ? 'accepted' : undefined}
        >
          {employeeText}
          {employee && (
            <EmployeeHoverBox
              employee={employee}
              jobRoleId={event.JobRoleId}
            />
          )}
        </NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>
          {event.status === 'unprocessed' ? 'open' : event.status}
        </NavLink>
      </td>
    </tr>
  );
};
