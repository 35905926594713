import moment from 'moment';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import Default from './Default';
import { EditOrderForm } from './EditOrderForm/EditOrderForm';

const Filled = () => {
  const { orderId } = useParams();

  const event = useGetJobEventQuery( orderId );
  const [ showHistory, setShowHistory ] = useState( false );
  const jobOffer = event.data?.JobOffer;

  const [ editModalOpened, setEditModalOpened ] = useState( false );

  const customStyles = {
    content: {
      maxWidth: 720,
    },
  };

  const toggleEditModal = open => () => setEditModalOpened( open );

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='white-shadow-box mb-1'>
            <div className='mb-3'>Employees</div>
            <div className='info-cols'>
              {jobOffer.AcceptedEmployee ? (
                <div className='info-col'>
                  <span className='info-col__label'>
                    {jobOffer?.AcceptedEmployee.firstName}{' '}
                    {jobOffer?.AcceptedEmployee.lastName}
                  </span>
                  <div>
                    <small className='text-success mr-2'>Offer accepted</small>
                    {/* <small className="text-danger pointer">Cancel the acceptance</small> */}
                  </div>
                </div>
              ) : (
                <div className='text-undefined'>Not assigned yet</div>
              )}
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'></div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <div className='info-col__label'>
                    {moment( event.data?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ReactModal
          isOpen={editModalOpened}
          ariaHideApp={false}
          contentLabel={'Edit'}
          style={customStyles}
        >
          <div className='modal-head'>
            <div className='modal-title'>Edit Event</div>
          </div>
          <EditOrderForm onClose={toggleEditModal( false )} />
        </ReactModal>
      </div>
    </>
  );
};

export default Filled;
