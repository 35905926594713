import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LocationsLegend from '../../../components/LocationsLegend';
import { useGetCompanyQuery } from '../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';

const CompanyDetails = () => {
  const { data: profile } = useGetProfileQuery();
  const company = useGetCompanyQuery( profile?.profile?.CompanyAccountId || skipToken );
  const { data: costCenters } = useGetCostCentersQuery();

  return (
    <div className='app-content'>
      <ErrorBoundary>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='company-details'>
                {company.data && (
                  <div className='company-details__wrapper'>
                    <h1 className='title error-title mt-4 mt-md-3'>
                      {company.data.name}
                    </h1>
                    <div className='company-details__info white-shadow-box'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='info-cols mb-5'>
                            <div className='info-col'>
                              <span className='info-col__label'>Business ID</span>
                              <strong className='info-col__title'>
                                {company.data.businessId}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Invoicing address</span>
                              <strong className='info-col__title'>
                                <span>{company.data.invoicingAddress_Street},</span>
                                <span>{company.data.invoicingAddress_ZipCode},</span>
                                <span>{company.data.invoicingAddress_City}</span>
                              </strong>
                            </div>
                          </div>

                          <div className='caption__title'>E-Invoicing address</div>
                          <div className='info-cols'>
                            <div className='info-col'>
                              <span className='info-col__label'>Operator Name</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OperatorName}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Operator Code</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OperatorCode}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>OVT Code</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OVTCode}
                              </strong>
                            </div>
                          </div>
                          <div className='info-cols mb-5'>
                            <div className='info-col'>
                              <span className='info-col__label'>Address</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_Address}
                              </strong>
                            </div>
                          </div>

                          <div className='caption__title'>Contact person</div>
                          <div className='info-cols mb-4'>
                            <div className='info-col'>
                              <span className='info-col__label'>Full Name</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_FullName}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Phone</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_Phone}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Email</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_Email}
                              </strong>
                            </div>
                          </div>
                        </div>

                        {profile?.profile.class === 'admin' && (
                          <div className='col-12 mt-2 d-flex justify-content-start align-items-end'>
                            <NavLink
                              className='btn btn--light-green'
                              to={'/company/edit'}
                            >
                              Edit company info
                            </NavLink>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='company-details__info white-shadow-box'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='section-title'>Locations</div>
                        </div>
                        {company.data?.Locations?.map( el => (
                          <div
                            key={el.id}
                            className='col-12 col-lg-3 mb-4'
                          >
                            <LocationsLegend
                              dataList={el}
                              costCenters={costCenters}
                            />
                          </div>
                        ) )}
                        <div className='col-12 mt-3'>
                          <NavLink
                            className='btn btn--light-green'
                            to={'/company/edit-locations'}
                          >
                            Edit locations
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    {/* <div className="company-details__info white-shadow-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="section-title">Company users</div>
                                                </div>
                                                {[].map(el =>
                                                    <div key={el.id} className="col-12 col-lg-6 mb-4">
                                                        <CompanyUsersLegend name="Customer admins"
                                                                            dataList={[1, 2, 3]}/>
                                                    </div>
                                                )}
                                                <div className="col-12 mt-4">
                                                    <NavLink className="btn btn--light-green btn--disabled" to="/mock">
                                                        Invite user
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default memo( CompanyDetails );
