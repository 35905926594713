import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';

const FormRowSelect = ( {
  options,
  title,
  name,
  className,
  defaultValue,
  disabled,
  style
} ) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  let formRowClassName = 'form-row w-100';
  if ( className ) formRowClassName += ' ' + className;

  useEffect( () => {
    if ( defaultValue && !values[name] ) {
      setFieldValue( name, defaultValue );
    }
  }, [ defaultValue, name, setFieldValue, values ] );

  return (
    <div className={formRowClassName}>
      <div className='select-box'>
        <label htmlFor={name}>{title}</label>
        <Field
          as='select'
          id={name}
          name={name}
          className='form-control'
          value={values[name]}
          onChange={handleChange}
          disabled={!options || options.length === 0 || disabled}
          style={style}
        >
          <option
            value=''
            label={`Select ${title}`}
            disabled
            hidden
          />
          {options?.map( option => (
            <option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ) )}
        </Field>
      </div>
    </div>
  );
};

export { FormRowSelect };
