import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo } from 'react';
import { useGetJobEventsPolling } from '../../../../hooks';
import { calcWeekEventsLengthSum } from '../../../../utils/dates/dates';
import { concat, getEmployeeWeekTiles } from '../../../../utils/utils';
import { EmployeeHoverBox } from '../../../EventListingScreen';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';

export const EmployeeRow = ( { employee, jobRoleId, setHoursByEmployee, className } ) => {
  const { weeksCount, weekStart, locationFilter, addEvent } = useContext(
    OperationsScreenContext
  );

  const { data: events } = useGetJobEventsPolling();

  const eventsByDate = useMemo( () => {
    if ( !events ) return {};
    const weekEvents = events.filter( event => {
      const start = DateTime.fromISO( event.start );
      const weeksDiff = start.weekNumber - weekStart.weekNumber;
      if ( weeksDiff < 0 || weeksDiff > weeksCount - 1 ) return false;
      const acceptedEmployeeId = event.JobOffer?.AcceptedEmployeeId;
      if ( locationFilter && event.JobRole.Location.id !== locationFilter.id ) return false;
      if ( jobRoleId && event.JobRoleId !== jobRoleId ) return false;
      return acceptedEmployeeId && acceptedEmployeeId === employee.id;
    } );
    const byDate = {};
    weekEvents.forEach( event => {
      const isoDate = DateTime.fromISO( event.start ).toISODate();
      if ( byDate[isoDate] ) {
        byDate[isoDate] = [ ...byDate[isoDate], event ];
      } else {
        byDate[isoDate] = [ event ];
      }
    } );
    return byDate;
  }, [ events, weekStart, jobRoleId, locationFilter ] );

  const eventsLength = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) =>
        calcWeekEventsLengthSum(
          Object.values( eventsByDate ).flat(),
          weekStart.weekNumber + weekIndex
        )
      );
  }, [ weeksCount, eventsByDate, weekStart ] );

  const tiles = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...getEmployeeWeekTiles(
          weekStart.plus( { week: weekIndex } ),
          eventsByDate,
          employee
        ),
        {
          className: styles['tile-total'],
          content: eventsLength[weekIndex].toFormat( 'hh:mm' )
        }
      ] );
  }, [ eventsByDate, weekStart, weeksCount ] );

  useEffect( () => {
    if ( setHoursByEmployee )
      setHoursByEmployee( prev => ( { ...prev, [employee.id]: eventsLength } ) );
  }, [ employee, eventsLength ] );

  useEffect( () => {
    for ( const event of Object.values( eventsByDate ).flat() ) {
      const date = DateTime.fromISO( event.start ).toISODate();
      addEvent( date, event );
    }
  }, [ addEvent, eventsByDate ] );

  if (
    !Object.values( eventsByDate )
      .flat()
      .filter( event => event.status !== 'open' ).length
  )
    return null;

  return (
    <>
      <div
        className={concat(
          styles['tile'],
          styles['tile-big'],
          styles['employee'],
          className
        )}
      >
        {employee.lastName}, {employee.firstName}
        <EmployeeHoverBox
          employee={employee}
          className={{
            container: styles['hover-box'],
            header: styles['header'],
            hoverEvent: styles['hover-event']
          }}
          jobRoleId={jobRoleId || 'all'}
        />
      </div>
      {tiles.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['tile'], className, day?.className )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
