import { Field, useFormikContext } from 'formik';

const SkillCheckbox = ( { skillId, skillName, name } ) => {
  const { handleChange } = useFormikContext();

  return (
    <label className='d-block mb-1 custom-checkbox'>
      <Field
        type='checkbox'
        name={name || 'eventSkills'}
        value={'' + skillId}
        onChange={handleChange}
      />
      <span className='ml-2'>{skillName}</span>
    </label>
  );
};

export default SkillCheckbox;
