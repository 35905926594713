import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import NoResultInTable from '../../components/NoResultInTable';
import { Spinner } from '../../components/Spinner';
import { useGetJobEventsPolling } from '../../hooks';
import { usePagination } from '../../hooks/usePagination';
import { eventListingActions } from '../../store/slices';
import {
  filterEventsByEmployee,
  filterEventsByJobRole,
  filterEventsByLocation,
  filterEventsByStatus
} from '../../utils/filters/eventsFilters';
import { eventsSortingsMap } from '../../utils/sortings/eventsSortings';
import { EventItem } from './EventItem';
import { EventListingControls } from './EventListingControls';
import { EventListingHeader } from './EventListingHeader';
import { theadData } from './data';

const EventListing = () => {
  const dispatch = useDispatch();

  const {
    costCenterName,
    statusFilter,
    sortBy,
    sortOrder,
    startDate,
    endDate,
    paginationState
  } = useSelector( state => state.filters );

  const { data: events, isLoading } = useGetJobEventsPolling( {
    start: startDate,
    end: endDate
  } );

  console.log( { startDate, endDate } );

  const [ searchTerm, setSearchTerm ] = useState( '' );

  const filteredEvents = useMemo( () => {
    if ( !events ) return [];

    let result = events;

    if ( searchTerm ) {
      const filteredByJobRole = filterEventsByJobRole( result, searchTerm );
      const filteredByLocation = filterEventsByLocation( result, searchTerm );
      const filteredByEmployee = filterEventsByEmployee( result, searchTerm );

      result = [
        ...new Set( [ ...filteredByEmployee, ...filteredByJobRole, ...filteredByLocation ] )
      ];
    }

    if ( statusFilter && statusFilter.value !== 'all' ) {
      const status = statusFilter.value;
      result = filterEventsByStatus(
        result,
        status === 'open' ? [ 'unprocessed', 'open' ] : [ statusFilter.value ]
      );
    }

    return result;
  }, [
    costCenterName?.value,
    events,
    statusFilter?.value,
    searchTerm,
    sortBy,
    sortOrder,
    startDate,
    endDate
  ] );

  const sortedEvents = useMemo( () => {
    if ( sortBy ) {
      return eventsSortingsMap[sortBy]( filteredEvents, sortOrder );
    }
    return filteredEvents;
  }, [ filteredEvents, sortBy, sortOrder ] );

  const { visibleItems, PaginationComponent, itemOffset, itemsPerPage } = usePagination(
    sortedEvents,
    {
      defaultState: paginationState
    }
  );

  useEffect( () => {
    dispatch( eventListingActions.setPaginationState( { itemsPerPage, itemOffset } ) );
  }, [ itemsPerPage, itemOffset ] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h1 className='title'>Event listing ({filteredEvents.length})</h1>
            </div>

            <EventListingControls
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <div className='col-12'>
              <table className='table'>
                <EventListingHeader />
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td
                        className='h-15'
                        colSpan={theadData.length}
                      >
                        <Spinner />
                      </td>
                    </tr>
                  ) : visibleItems.length ? (
                    visibleItems.map( ( event, index ) => (
                      <EventItem
                        event={event}
                        key={index}
                      />
                    ) )
                  ) : (
                    <NoResultInTable colSpan={theadData.length} />
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={theadData.length}>{PaginationComponent}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default EventListing;
