import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import notificationIcon from '../../assets/images/notofication.svg';
import ErrorBoundary from '../../components/ErrorBoundary';
import NoResultInTable from '../../components/NoResultInTable';
import { SearchPanel } from '../../components/SearchPanel';
import { Spinner } from '../../components/Spinner';
import { usePagination } from '../../hooks/usePagination';
import { searchByFirstAndLastName } from '../../store/actions/appActions';
import { useGetProfileQuery } from '../../store/apis/currentUserApi';
import { useGetCustomerUsersQuery } from '../../store/apis/customerUsersApi';
import { useGetPendingInvitesQuery } from '../../store/apis/invitationApi';
import InviteModal from './InviteModal';

const Accounts = ( { title } ) => {
  const { data: profile } = useGetProfileQuery();
  const { data: customerUsers, isLoading } = useGetCustomerUsersQuery();
  const { data: pendingInvites } = useGetPendingInvitesQuery();

  const [ searchTerm, setSearchTerm ] = useState( '' );

  const usersWithInvites = useMemo( () => {
    if ( !customerUsers ) return [];
    return customerUsers.concat( pendingInvites || [] );
  }, [ customerUsers, pendingInvites ] );

  const [ modalIsOpen, setIsOpen ] = useState( false );

  const filteredItems = useMemo(
    () => searchByFirstAndLastName( usersWithInvites, searchTerm ),
    [ usersWithInvites, searchTerm ]
  );

  const { visibleItems, PaginationComponent } = usePagination( filteredItems );

  const openModal = () => setIsOpen( true );
  const closeModal = () => setIsOpen( false );

  const getUserLink = id => `/customer-users/${id}`;

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title error-title'>{title}</h1>
            </div>
            <div className='col-12 col-md-6'>
              <div className='filter-group joblink-users-filter-group'>
                <div className='search'>
                  <SearchPanel
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 mt-4 mb-4 mb-md-0 mt-md-0'>
              <div className='d-flex justify-content-md-end'>
                {profile?.profile.class === 'admin' && (
                  <button
                    className='btn btn-md-block btn--green'
                    onClick={openModal}
                  >
                    Send invite
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mt-smd-0 mt-40'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    {
                      <>
                        <th>Phone</th>
                        <th>Class</th>
                      </>
                    }
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={2}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : visibleItems.length ? (
                    visibleItems.map( ( el, index ) => (
                      <tr key={index}>
                        <td className='link-in'>
                          <Link to={el.id && getUserLink( el.id )}>
                            {/* {console.log( 'el ', getUserLink( el.id ) )} */}
                            {el.firstName} {el.lastName}
                            {!el.id && (
                              <img
                                className='link-in__notification'
                                src={notificationIcon}
                              />
                            )}
                          </Link>
                        </td>
                        <td className='link-in'>
                          <Link to={el.id && getUserLink( el.id )}>{el.email}</Link>
                        </td>
                        {
                          <>
                            <td>
                              <Link to={el.id && getUserLink( el.id )}>{el.phone}</Link>
                            </td>
                            <td>
                              <Link to={el.id && getUserLink( el.id )}>{el.class}</Link>
                            </td>
                          </>
                        }
                      </tr>
                    ) )
                  ) : (
                    <NoResultInTable colSpan={2} />
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>{PaginationComponent}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <InviteModal
        maxWidth={680}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
    </ErrorBoundary>
  );
};

export default Accounts;
