import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../../assets/images/operations/add-icon.svg';
import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEventJobRole } from './BadgeHoverBoxEventJobRole';

export const HoverBoxJobRole = ( {
  events,
  date,
  jobRole,
  location,
  hideEmployees,
  className
} ) => {
  const history = useHistory();

  const goCreateOrder = () => {
    history.push( '/new-order', {
      costCenterId: location.CostCenterId,
      locationId: location.id,
      jobRoleId: jobRole.id,
      companyId: location.CompanyAccount.name,
      date: date
        .set( {
          hour: DateTime.now().hour
        } )
        .toJSDate()
    } );
  };

  return (
    <BadgeHoverBox
      date={date}
      className={className}
    >
      {events.map( event => (
        <BadgeHoverBoxEventJobRole
          event={event}
          key={event.id}
          hideEmployees={hideEmployees}
        />
      ) )}
      <div
        onClick={goCreateOrder}
        className={styles['button-create']}
      >
        <AddIcon />
        Create order
      </div>
    </BadgeHoverBox>
  );
};
