import { useFormikContext } from 'formik';
import { useGetLocationsQuery } from '../../store/apis/locationsApi';

export const LocationsJobRolesField = ( { display } ) => {
  const formikContext = useFormikContext();

  const values = formikContext?.values;
  const setFieldValue = formikContext?.setFieldValue;

  const { data: locations } = useGetLocationsQuery();

  const locationsIds = display?.LocationIds || values?.LocationIds;
  const jobRolesIds = display?.JobRoleIds || values?.JobRoleIds;

  const onLocationChange = location => () => {
    if ( values.LocationIds.includes( location.id ) ) {
      setFieldValue(
        'LocationIds',
        values.LocationIds.filter( id => id !== location.id )
      );
      setFieldValue(
        'JobRoleIds',
        values.JobRoleIds.filter(
          id => !location.JobRoles.map( jobRole => jobRole.id ).includes( id )
        )
      );
    } else {
      setFieldValue( 'LocationIds', [ ...values.LocationIds, location.id ] );
    }
  };

  const onJobRoleChange = jobRole => () => {
    if ( values.JobRoleIds.includes( jobRole.id ) ) {
      setFieldValue(
        'JobRoleIds',
        values.JobRoleIds.filter( id => id !== jobRole.id )
      );
    } else {
      setFieldValue( 'JobRoleIds', [ ...values.JobRoleIds, jobRole.id ] );
    }
  };

  return (
    <div className='checkboxes'>
      {locations?.map( location => {
        const isLocationChecked = locationsIds.includes( location.id );

        return !!display && !isLocationChecked ? null : (
          <div
            className='checkboxes-item'
            key={location.id}
          >
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={locationsIds.includes( location.id )}
                onChange={display ? undefined : onLocationChange( location )}
                disabled={!!display}
              />
              <span>{location.name}</span>
            </label>
            {isLocationChecked && (
              <div className='inner-checkboxes'>
                {location.JobRoles.map( jobRole =>
                  !!display && !jobRolesIds.includes( jobRole.id ) ? null : (
                    <div
                      className='checkboxes-item'
                      key={jobRole.id}
                    >
                      <label className='custom-checkbox'>
                        <input
                          type='checkbox'
                          checked={jobRolesIds.includes( jobRole.id )}
                          onChange={display ? undefined : onJobRoleChange( jobRole )}
                          disabled={!!display}
                        />
                        <span>{jobRole.name}</span>
                      </label>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        );
      } )}
    </div>
  );
};
