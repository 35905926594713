import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { concat, roundHalf } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';

export const BadgeHoverBoxEvent = ( { event, children, isLink = true, className } ) => {
  const start = useMemo( () => DateTime.fromISO( event.start ), [ event ] );
  const end = useMemo( () => DateTime.fromISO( event.end ), [ event ] );
  const hoursDiff = useMemo( () => roundHalf( end.diff( start, 'hours' ).hours ) );

  const render = () => (
    <>
      <p>
        {event.JobRole.name} - {event.JobRole.Location.name}
      </p>
      <p>
        <span>
          {start.toFormat( 'HH:mm' )} - {end.toFormat( 'HH:mm' )}
        </span>
        <span>{hoursDiff} hours</span>
      </p>
      {children}
    </>
  );

  return isLink ? (
    <Link
      to={`/event-listing/${event.id}`}
      className={concat( styles['hover-event'], className )}
    >
      {render()}
    </Link>
  ) : (
    <div className={concat( styles['hover-event'], className )}>{render()}</div>
  );
};
