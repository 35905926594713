import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import { concat } from '../../../../utils/utils';

export const TaskField = ( { className } ) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  const jobRoleId = +values.jobRole;

  const { data: jobRole } = useGetJobRoleQuery( jobRoleId || skipToken );
  const { data: presetTasks } = useGetPresetTasksQuery();

  const options = useMemo( () => {
    if ( !jobRole || !presetTasks ) return [];
    if ( jobRoleId !== jobRole.id ) return [];
    return jobRole.Tasks.map( task => ( {
      label: presetTasks.find( presetTask => presetTask.id === task.PresetTaskId ).name,
      value: task.id
    } ) );
  }, [ jobRole, presetTasks, jobRoleId ] );

  useEffect( () => {
    if ( jobRoleId !== jobRole?.id ) setFieldValue( 'task', '' );
  }, [ jobRoleId, jobRole ] );

  useEffect( () => {
    if ( options.length ) {
      setFieldValue( 'task', options[0].value );
    }
  }, [ options ] );

  return (
    <div
      className={concat( 'form-row', 'w-100', className )}
      style={options.length <= 1 ? { display: 'none' } : undefined}
    >
      <div className='select-box'>
        <label htmlFor='task'>Task</label>
        <Field
          as='select'
          id={'task'}
          name={'task'}
          className='form-control'
          value={values['task']}
          onChange={handleChange}
          disabled={!options || options.length === 0}
        >
          <option
            value=''
            label={'Select task'}
            disabled
            hidden
          />
          {options?.map( option => (
            <option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ) )}
        </Field>
      </div>
    </div>
  );
};
