import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useGetJobEventsPolling } from '../../hooks';
import { filterEventsByStatus } from '../../utils/filters/eventsFilters';
import ErrorBoundary from '../ErrorBoundary';
import { Spinner } from '../Spinner';
import './Orders.scss';
import { UpcomingEvent } from './UpcomingEvent';

const Orders = ( { status } ) => {
  const { data: events } = useGetJobEventsPolling();

  const visibleEvents = useMemo( () => {
    if ( !events ) return [];
    let result = events;
    if ( status && status !== 'all' ) {
      result =
        status === 'open'
          ? filterEventsByStatus( result, [ 'unprocessed', 'open' ] )
          : filterEventsByStatus( result, [ status ] );
    }
    result = result.filter(
      event =>
        DateTime.now() < DateTime.fromISO( event.start ).plus( { hours: 2 } ) &&
        [ 'open', 'unprocessed', 'filled', 'failed' ].includes( event.status ) &&
        DateTime.now().plus( { days: 10 } ).endOf( 'day' ) > DateTime.fromISO( event.end )
    );
    return result.sort( ( a, b ) => ( new Date( a.start ) > new Date( b.start ) ? 1 : -1 ) );
  }, [ events, status ] );

  if ( !events ) return <Spinner />;

  return (
    <ErrorBoundary>
      <ul className='orders-list'>
        {visibleEvents.length ? (
          visibleEvents.map( event => (
            <UpcomingEvent
              key={event.id}
              event={event}
            />
          ) )
        ) : (
          <div className='no-results'>Sorry, no results found</div>
        )}
      </ul>
    </ErrorBoundary>
  );
};

export default Orders;
