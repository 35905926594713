import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { useHistory } from 'react-router-dom';
import LinkBack from '../../../components/LinkBack';
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation
} from '../../../store/apis/companiesApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';
import EditCompanyForm from './EditCompanyInfoForm';

export const EditCompanyInfoScreen = () => {
  const history = useHistory();

  const { data: profile } = useGetProfileQuery();
  const companyId = profile?.profile?.CompanyAccountId;

  const company = useGetCompanyQuery( companyId || skipToken );
  const [ updateCompany ] = useUpdateCompanyMutation();

  const onSubmit = ( id, fields, setSubmitting ) => {
    updateCompany( { id, fields } ).then( () => {
      setSubmitting( false );
      history.goBack();
    } );
  };

  return (
    <div className='app-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='link-back'>
              <LinkBack title='Back to Company' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='company-details editing-company-info'>
              {company.data && (
                <>
                  <div className='company-details__wrapper'>
                    <div className='row'>
                      <div className='col-12 col-lg-6'>
                        <h1 className='title error-title mt-4 mt-md-3'>
                          Company editing
                        </h1>
                      </div>
                    </div>
                  </div>
                  <EditCompanyForm
                    company={company.data}
                    onSubmit={onSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
