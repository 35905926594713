export const theadData = [
  'Start time',
  'Hours',
  'Location',
  'Job role',
  'Status',
  'Documents'
];

export const filterOptions = [
  { value: 'all', label: 'Show all' },
  { value: 'open', label: 'Open' },
  { value: 'filled', label: 'Filled' },
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
  { value: 'canceled', label: 'Canceled' }
];
