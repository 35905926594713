import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useGetCompanyQuery } from '../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';
import { useCreateJobEventMutation } from '../../../store/apis/eventsApi';
import {
  EndTimeField,
  JobRoleField,
  LocationField,
  SkillsField,
  StartTimeField,
  TaskField
} from './components';
import { createOrderFormSchema } from './formValidation';

const CreateOrderForm = () => {
  const history = useHistory();
  const location = useLocation();

  const { data: costCenters } = useGetCostCentersQuery();
  const { data: profile } = useGetProfileQuery();
  const { data: company } = useGetCompanyQuery(
    profile?.profile.CompanyAccountId || skipToken
  );
  const [ createJobEvent ] = useCreateJobEventMutation();

  const initStartTime = useMemo( () => {
    const now = DateTime.now();

    return DateTime.fromObject( {
      ...now.toObject(),
      minute: now.minute > 30 ? 0 : 30,
      hour: now.minute > 30 ? now.hour + 1 : now.hour,
      second: 0,
      millisecond: 0
    } );
  }, [] );

  const initialValues = {
    costCenter: location.state?.costCenterId || company?.Locations[0]?.CostCenterId,
    location: location.state?.locationId || '',
    company: location.state?.companyId || '',
    jobRole: location.state?.jobRoleId || '',
    task: '',
    startTime: initStartTime.toJSDate(),
    endTime: initStartTime.plus( { hours: 2 } ).toJSDate(),
    irregularTask: false,
    JobEndsInNextDay: false,
    eventSkills: [],
    description: '',
    OfferAccepted: false,
    selectAllSkills: true,
    allSelected: true
  };

  if ( !profile || !costCenters || !company ) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={( values, { setSubmitting } ) => {
        createJobEvent( values )
          .unwrap()
          .then( () => {
            setSubmitting( false );
            history.goBack();
          } );
      }}
      validationSchema={createOrderFormSchema}
      validateOnMount={true}
    >
      {( { values, handleChange, handleSubmit, isSubmitting, isValid } ) => (
        <Form
          onSubmit={handleSubmit}
          className='white-shadow-box pb-4'
        >
          <div className='form__body'>
            <div className='form-group'>
              <LocationField />
              <JobRoleField className='mr-4' />
              <TaskField />
            </div>
            <div className='form-group'>
              <StartTimeField />
              <EndTimeField />
            </div>
            <div className='form-group'>
              <SkillsField />
              <div className='form-row w-100'>
                <label
                  className='ml-0'
                  htmlFor='description'
                >
                  Description
                </label>
                <Field
                  as='textarea'
                  id='description'
                  name='description'
                  className='form-control h-350'
                  value={values.description}
                  onChange={handleChange}
                  placeholder='Type message here...'
                />
              </div>
            </div>
          </div>
          <div className='form-footer'>
            <div className='mt-4'>
              <div className='form-group align-items-center'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                >
                  Send
                </button>
                <div
                  className='checkboxes-item ml-md-1'
                  style={{ display: 'none' }}
                >
                  <label className='custom-checkbox custom-checkbox--default-color mt-4 mt-md-2 ml-0'>
                    <Field
                      type='checkbox'
                      name='OfferAccepted'
                    />
                    <span className='ml-1'>
                      Send notification when Job Offer will be Accepted
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrderForm;
