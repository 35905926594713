import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../../assets/images/operations/add-icon-light.svg';
import styles from '../../OperationsScreen.module.sass';
import { HoverBoxJobRole } from './HoverBoxJobRole';

export const EventsCountBadge = ( { events = [], date, jobRole, location } ) => {
  const history = useHistory();

  const [ hoverBoxTop, setHoverBoxTop ] = useState( false );

  const goCreateOrder = () => {
    history.push( '/new-order', {
      costCenterId: location.CostCenterId,
      locationId: location.id,
      jobRoleId: jobRole.id,
      companyId: location.CompanyAccount.name,
      date: date
        .set( {
          hour: DateTime.now().hour
        } )
        .toJSDate()
    } );
  };

  const badgeRef = useCallback( element => {
    if ( element ) {
      setHoverBoxTop(
        ( window.scrollY + element.getBoundingClientRect().y ) /
          document.body.offsetHeight >
          0.9
      );
    }
  }, [] );

  if ( !events?.length )
    return (
      <AddIcon
        className={styles['badge-add']}
        onClick={goCreateOrder}
      />
    );

  return (
    <div
      className={styles['badge-count']}
      ref={badgeRef}
    >
      <span>{events.length}</span>
      <HoverBoxJobRole
        date={date}
        events={events}
        jobRole={jobRole}
        location={location}
        className={hoverBoxTop ? styles['top'] : undefined}
        hideEmployees
      />
    </div>
  );
};
