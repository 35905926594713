import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePickerField from '../../../../components/DatePickerField';
import {
  useGetJobEventQuery,
  useUpdateJobOfferMutation,
  useUpdateJobOrderDescriptionMutation,
  useUpdateJobOrderMutation
} from '../../../../store/apis/eventsApi';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import { FormRowSelect } from '../../../NewOrderScreen/CreateOrderForm/FormRowSelect';
import SelectAllSkillsCheckbox from '../../../NewOrderScreen/CreateOrderForm/SelectAllSkillsCheckbox';
import SkillCheckbox from '../../../NewOrderScreen/CreateOrderForm/SkillCheckbox';
import { FormController } from './FormController';

export const EditOrderForm = ( { onClose } ) => {
  const { orderId } = useParams();

  const event = useGetJobEventQuery( orderId || skipToken );
  const jobRole = useGetJobRoleQuery( event.data ? event.data.JobRoleId : skipToken );
  const presetTasks = useGetPresetTasksQuery();

  const [ updateOrder ] = useUpdateJobOrderMutation();
  const [ updateOrderDescription ] = useUpdateJobOrderDescriptionMutation();
  const [ updateOfferDescription ] = useUpdateJobOfferMutation();

  const [ skillsOptions, setSkillsOptions ] = useState( [] );

  const getTaskName = useCallback(
    task => {
      if ( !presetTasks.data ) return '';
      return presetTasks.data.find( presetTask => presetTask.id === task.PresetTaskId )
        ?.name;
    },
    [ presetTasks.data ]
  );

  if ( !event.data || !jobRole.data || !presetTasks.data ) return null;

  const tasksOptions = jobRole.data.Tasks.map( task => ( {
    value: task.id,
    label: getTaskName( task ),
  } ) );

  const initialValues = {
    start: new Date( event.data.start ),
    end: new Date( event.data.end ),
    TaskId: event.data.TaskId,
    EventSkills: event.data.EventSkills.map( s => s.SkillId + '' ),
    selectAllSkills: false,
    allSelected: false,
    description:
      event.data.phase === 'order'
        ? event.data.JobOrder.description
        : event.data.JobOffer.description,
  };

  const onSubmit = values => {
    // eslint-disable-next-line no-unused-vars
    const { description, selectAllSkills, allSelected, ...fields } = values;
    fields.EventSkills = fields.EventSkills.map( skillId => ( {
      SkillId: +skillId,
    } ) );
    fields.TaskId = +fields.TaskId;

    const updatePromises = [];

    if ( event.phase === 'order' )
      updatePromises.push( updateOrder( { fields, eventId: orderId } ).unwrap() );

    if ( description !== initialValues.description ) {
      updatePromises.push(
        event.data.phase === 'order'
          ? updateOrderDescription( { description, eventId: orderId } ).unwrap()
          : updateOfferDescription( { description, eventId: orderId } ).unwrap()
      );
    }

    Promise.all( updatePromises ).then( () => {
      onClose();
    } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <FormController
          jobRole={jobRole.data}
          setSkillsOptions={setSkillsOptions}
          skillsOptions={skillsOptions}
        />
        <div className='form-row w-100'>
          <label
            className='ml-0'
            htmlFor='description'
          >
            Description
          </label>
          <Field
            as='textarea'
            id='description'
            name='description'
            className='form-control h-350'
            placeholder='Type message here...'
          />
        </div>
        <div className='form-group'>
          <div className='form-row mr-4 w-100'>
            <label htmlFor='start-time'>Start time</label>
            <DatePickerField
              name='start'
              id='start-time'
              showTimeSelect
              dateFormat='dd/MM/yyyy HH:mm'
            />
          </div>
          <div className='form-row w-100'>
            <label htmlFor='end-time'>End time</label>
            <DatePickerField
              name='end'
              id='end-time'
              showTimeSelect
              dateFormat='dd/MM/yyyy HH:mm'
              wrapperClassName='w-100'
            />
          </div>
        </div>
        <div className='form-group'>
          <FormRowSelect
            title='Task'
            name='TaskId'
            options={tasksOptions}
            defaultValue={tasksOptions.length ? tasksOptions[0].value : undefined}
          />
        </div>
        <div className='form-group'>
          <div className='form-row w-100'>
            <label className='mb-2'>Required skills</label>
            <div
              className='required-skills-column'
              role='group'
              aria-labelledby='checkbox-group'
            >
              <SelectAllSkillsCheckbox disabled={!skillsOptions.length} />
              {skillsOptions.map( skillOption => (
                <SkillCheckbox
                  key={skillOption.value}
                  name='EventSkills'
                  skillId={skillOption.value}
                  skillName={skillOption.label}
                />
              ) )}
            </div>
          </div>
        </div>
        <div className='form-footer'>
          <div className='form-group align-items-center'>
            <button
              className='btn btn--green'
              type='submit'
              disabled={false}
            >
              Send
            </button>
            <button
              className='btn btn--default'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
