import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorBoundary from '../../components/ErrorBoundary';
import LinkBack from '../../components/LinkBack';
import { LocationsJobRolesField } from '../../components/LocationsJobRolesField';
import { Spinner } from '../../components/Spinner';
import { fieldErrorClass } from '../../constants';
import { useGetProfileQuery } from '../../store/apis/currentUserApi';
import {
  useGetCustomerUserQuery,
  useUpdateCustomerUserMutation
} from '../../store/apis/customerUsersApi';

export const CustomerUserEditScreen = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data: currentUser } = useGetProfileQuery();
  const { data: customerUser } = useGetCustomerUserQuery( id );

  const [ updateCustomerUser ] = useUpdateCustomerUserMutation();

  if ( !customerUser || !currentUser ) return <Spinner />;

  const initialValues =
    currentUser.profile.class === 'admin'
      ? {
          phone: customerUser.phone,
          email: customerUser.email,
          firstName: customerUser.firstName,
          lastName: customerUser.lastName,
          LocationIds: customerUser.LocationIds,
          JobRoleIds: customerUser.JobRoleIds
        }
      : {
          phone: customerUser.phone,
          email: customerUser.email,
          firstName: customerUser.firstName,
          lastName: customerUser.lastName
        };

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div>
                <LinkBack title='Back to Customer Users' />
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <div className='title-box mt-4 mt-md-3'>
                  <h1 className='title error-title mb-0'>
                    {customerUser.firstName} {customerUser.lastName}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <Formik
                initialValues={initialValues}
                onSubmit={( values, { setSubmitting } ) => {
                  updateCustomerUser( {
                    id,
                    data: values
                  } )
                    .unwrap()
                    .then( () => {
                      setSubmitting( false );
                      history.goBack();
                    } );
                }}
              >
                {( {
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  handleChange,
                  isSubmitting
                } ) => (
                  <Form onSubmit={handleSubmit}>
                    <div className='white-shadow-box mb-3 pb-3 pt-3'>
                      <div className='form-group'>
                        <div className='form-row w-100'>
                          <label htmlFor='firstName'>First Name</label>
                          <Field
                            className={`form-control ${
                              errors.firstName && touched.firstName && fieldErrorClass
                            }`}
                            type='text'
                            id='firstName'
                            name='firstName'
                            value={values.firstName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className='form-row w-100'>
                          <label htmlFor='lastName'>Last name</label>
                          <Field
                            className={`form-control ${
                              errors.lastName && touched.lastName && fieldErrorClass
                            }`}
                            type='text'
                            id='lastName'
                            name='lastName'
                            value={values.lastName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='form-row w-100'>
                          <label htmlFor='email'>Email</label>
                          <Field
                            className={`form-control ${
                              errors.email && touched.email && fieldErrorClass
                            }`}
                            type='text'
                            id='email'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className='form-row w-100'>
                          <label htmlFor='phone'>Last name</label>
                          <Field
                            className={`form-control ${
                              errors.phone && touched.phone && fieldErrorClass
                            }`}
                            type='text'
                            id='phone'
                            name='phone'
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {currentUser?.profile.class === 'admin' &&
                        customerUser.class !== 'admin' && (
                          <div className='form-group'>
                            <div
                              className='form-row'
                              style={{ maxWidth: 400 }}
                            >
                              <label>Locations and Job Roles</label>
                              <LocationsJobRolesField />
                            </div>
                          </div>
                        )}
                      <div className='form-footer d-flex justify-content-between align-items-center'>
                        <div className='btn-group'>
                          <button
                            className='btn btn--green'
                            type='submit'
                            disabled={isSubmitting}
                          >
                            Save
                          </button>
                          <button
                            className='btn btn--default'
                            onClick={history.goBack}
                            type='button'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
