import { useFormikContext } from 'formik';

const SelectAllSkillsCheckbox = ( { disabled } ) => {
  const { values, setFieldValue } = useFormikContext();

  const onChange = () => {
    setFieldValue( 'selectAllSkills', !values.selectAllSkills );
  };

  return (
    <label
      className={`d-block mb-1 select-all custom-checkbox ${
        disabled && 'disabled'
      }`}
    >
      <input
        type='checkbox'
        checked={values.allSelected}
        onChange={onChange}
        name='selectAllSkills'
      />
      <span className='ml-2'>Select all</span>
    </label>
  );
};

export default SelectAllSkillsCheckbox;
