import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { DateField } from './DateField';

export const EndTimeField = () => {
  const { values, setFieldValue } = useFormikContext();
  useEffect( () => {
    setFieldValue(
      'endTime',
      DateTime.fromJSDate( values.startTime ).plus( { hours: 2 } ).toJSDate()
    );
  }, [ values.startTime ] );

  return (
    <DateField
      name='endTime'
      label='End time'
      filterTime={values => time => {
        return time > values.startTime;
      }}
      filterDate={values => date =>
        date.getDate() >= values.startTime.getDate() &&
        date.getMonth() >= values.startTime.getMonth() &&
        date.getFullYear() >= values.startTime.getFullYear()}
    />
  );
};
