import { useMemo } from 'react';
import Select from 'react-select';
import { selectTheme } from '../../theme';

export const StyledSelect = ( {
  options,
  onChange,
  value,
  containerStyle,
  ...selectProps
} ) => {
  const onOptionChange = option => {
    if ( Array.isArray( option ) ) {
      onChange( option.map( opt => opt.value ) );
    } else {
      onChange( option.value );
    }
  };

  const valueOption = useMemo( () => {
    if ( !value ) return undefined;
    if ( Array.isArray( value ) )
      return value.map( val => options.find( option => option.value === val ) );
    return options.find( option => option.value === value );
  }, [ value, options ] );

  return (
    <Select
      options={options}
      onChange={onOptionChange}
      value={valueOption}
      theme={selectTheme}
      styles={{
        control: base => ( { ...base, height: '100%' } ),
        container: base => ( {
          ...base,
          height: 40,
          width: '100%',
          maxWidth: '300px',
          minWidth: '200px',
          marginLeft: 12,
          ...( containerStyle || {} )
        } )
      }}
      {...selectProps}
    />
  );
};
