import React from 'react';
import AccountsScreen from '../../screens/AccountsScreen';
import { CustomerUserEditScreen } from '../../screens/AccountsScreen/CustomerUserEditScreen';
import { CustomerUserScreen } from '../../screens/AccountsScreen/CustomerUserScreen';
import CompanyDetailsScreen from '../../screens/CompaniesScreen/CompanyDetailsScreen';
import { EditCompanyInfoScreen } from '../../screens/CompaniesScreen/EditCompanyInfoScreen';
import { EditCompanyLocationsScreen } from '../../screens/CompaniesScreen/EditCompanyLocationsScreen';
import DashboardScreenContainer from '../../screens/DashboardScreen/DashboardScreen';
import EventListingContainer from '../../screens/EventListingScreen';
import JobLogsProcessingScreen from '../../screens/JobLogsProcessingScreen';
import MessagesScreen from '../../screens/MessagesScreen';
import ChatScreen from '../../screens/MessagesScreen/ChatScreen/ChatScreen.jsx';
import NewOrderContainer from '../../screens/NewOrderScreen';
import { OperationsScreen } from '../../screens/OperationsScreen';
import NewOrderReceived from '../../screens/OrderReceivedScreen';

export const AccountsSubMenu = [
  {
    id: 1,
    to: '/company',
    label: '- Company'
  },
  {
    id: 2,
    to: '/customer-users',
    label: '- Users'
  }
];

const routes = [
  {
    path: '/',
    exact: true,
    component: <DashboardScreenContainer />
  },
  {
    path: '/messages',
    component: <MessagesScreen title='Messages' />
  },
  {
    path: '/event-listing',
    exact: true,
    component: <EventListingContainer />
  },
  {
    path: '/event-listing/:orderId',
    exact: true,
    component: <NewOrderReceived />
  },
  {
    path: '/new-order',
    component: <NewOrderContainer />
  },
  {
    path: '/job-logs-processing',
    component: <JobLogsProcessingScreen title='Job logs processing' />
  },
  {
    path: '/company',
    exact: true,
    component: <CompanyDetailsScreen />,
    adminOnly: true
  },
  {
    path: '/company/edit',
    exact: true,
    component: <EditCompanyInfoScreen />,
    adminOnly: true
  },
  {
    path: '/company/edit-locations',
    exact: true,
    component: <EditCompanyLocationsScreen />,
    adminOnly: true
  },
  {
    path: '/customer-users',
    exact: true,
    component: (
      <AccountsScreen
        title='Customer Users'
        role='customerUsers'
      />
    )
  },
  {
    path: '/customer-users/:id',
    exact: true,
    component: <CustomerUserScreen />
  },
  {
    path: '/customer-users/:id/edit',
    exact: true,
    component: <CustomerUserEditScreen />
  },
  {
    path: '/chat/:id',
    component: <ChatScreen />
  },
  {
    path: '/operations',
    component: <OperationsScreen />
  }
];

export default routes;
