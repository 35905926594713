import { createContext } from 'react';

export const OperationsScreenContext = createContext( {
  eventsByDate: {},
  addEvent: undefined,
  weekStart: undefined,
  weeksCount: 2,
  costCenter: 'all',
  employeeFilter: undefined,
  setEmployeeFilter: undefined,
  locationFilter: undefined,
  setLocationFilter: undefined,
  acceptedEventsByEmployee: [],
  receivedEventsByEmployee: [],
} );
