import React, { useEffect, useState } from 'react';

import './filterMessage.sass';

export const ChatGroups = ( { onChange, unreadCount } ) => {
  const [ active, setActive ] = useState( 'all' );
  const toggleActive = value => () => setActive( value );

  useEffect( () => {
    if ( onChange ) onChange( active );
  }, [ active ] );

  return (
    <div className='filter-message'>
      <ul>
        {[
          { id: 'all', name: 'All' },
          { id: 'unread', name: 'Unread' },
        ].map( ( item, index ) => {
          return (
            <li
              key={index}
              className={active === item.id ? 'filter-active' : undefined}
              onClick={toggleActive( item.id )}
            >
              {item.name}
              {item.id === 'unread' ? <span>{unreadCount}</span> : null}
            </li>
          );
        } )}
      </ul>
    </div>
  );
};
