import { useContext, useMemo } from 'react';
import {
  calcEventsLengthSum,
  calcWeekEventsLengthSum
} from '../../../../utils/dates/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';
import { HeaderDay } from './HeaderDay';
import { HeaderTotal } from './HeaderTotal';
import { HeaderWeekLabel } from './HeaderWeekLabel';

export const HeaderRow = ( { week } ) => {
  const { eventsByDate, weeksCount } = useContext( OperationsScreenContext );

  const weeks = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, i ) => ( {
            content: <HeaderDay day={week.plus( { days: i, weeks: weekIndex } )} />
          } ) ),
        { content: <HeaderTotal /> }
      ] );
  }, [ week, weeksCount ] );

  const timeWeeks = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, i ) => {
            const isoDate = week.plus( { days: i, week: weekIndex } ).toISODate();
            const events = eventsByDate[isoDate];
            if ( events ) {
              const length = calcEventsLengthSum( events );
              return {
                content: length.toFormat( 'hh:mm' )
              };
            }
            return {
              content: undefined
            };
          } ),
        {
          content: calcWeekEventsLengthSum(
            Object.values( eventsByDate ).flat(),
            week.weekNumber + weekIndex
          ).toFormat( 'hh:mm' )
        }
      ] );
  }, [ week, eventsByDate, weeksCount ] );

  return (
    <>
      {Array( weeksCount )
        .fill()
        .map( ( _, i ) => (
          <HeaderWeekLabel
            weekDate={week.plus( { weeks: i } )}
            key={i}
          />
        ) )}
      <div className={concat( styles['header-tile'], styles['header-tile-main'] )}>
        Employee
      </div>
      {weeks.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['header-tile'], styles[day?.className] )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}

      <div
        className={concat(
          styles['header-tile'],
          styles['header-tile-main'],
          styles['header-time']
        )}
      ></div>
      {timeWeeks.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['header-tile'], styles['header-time'] )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
