import { useContext, useMemo } from 'react';
import { Spinner } from '../../../../components/Spinner';
import { useGetLocationsQuery } from '../../../../store/apis/locationsApi';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';
import { JobRoleGroup } from '../rows';

export const JobRolesGroupLayout = () => {
  const { weekStart } = useContext( OperationsScreenContext );

  const { data: locations } = useGetLocationsQuery();

  const groups = useMemo( () => {
    if ( !locations ) return [];
    let visibleLocations = [ ...locations ];
    const locationsSorted = visibleLocations.sort( ( a, b ) => ( a.name > b.name ? 1 : -1 ) );
    let items = [];
    for ( const location of locationsSorted ) {
      if ( location.JobRoles?.length ) {
        for ( const jobRole of [ ...location.JobRoles ].sort( ( a, b ) =>
          a.name > b.name ? 1 : -1
        ) ) {
          items = items.concat( {
            location,
            jobRole
          } );
        }
      }
    }
    return items;
  }, [ locations ] );

  if ( !locations ) return <Spinner className={styles['spinner']} />;

  return groups.map( ( group, index ) => (
    <JobRoleGroup
      jobRole={group.jobRole}
      location={group.location}
      week={weekStart}
      key={index}
    />
  ) );
};
