import { baseInstance } from './instances';

const invitationAPI = {
  registerCustomerUser: async ( token, password ) => {
    return invitationAPI
      .getInvitationData( token )
      .then( data =>
        data
          ? baseInstance.post( `/invitation/customerUser/register/${token}`, {
              firstName: data.payload.firstName,
              lastName: data.payload.lastName,
              phone: data.payload.phone,
              password
            } )
          : null
      )
      .then( res => res.status === 200 )
      .catch( error => console.log( error ) );
  },
  getInvitationData: async token => {
    return baseInstance
      .get( `/invitation/retrieve/${token}` )
      .then( res => ( res.status === 200 ? res.data : false ) );
  }
};

export default invitationAPI;
