export const sortEventsByEmployeeName = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aName = a.JobOffer.AcceptedEmployee.firstName.concat(
      a.JobOffer.AcceptedEmployee.lastName
    );
    const bName = b.JobOffer.AcceptedEmployee.firstName.concat(
      b.JobOffer.AcceptedEmployee.lastName
    );

    const sortOrderCoefficient = order === 'asc' ? 1 : -1;

    console.log( 'sortOrderCoefficient ', ( aName > bName ? 1 : -1 ) * sortOrderCoefficient );
    return ( aName > bName ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const sortEventsByStartTime = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aDate = new Date( a.start );
    const bDate = new Date( b.start );
    const sortOrderCoefficient = order === 'desc' ? -1 : 1;
    return ( aDate > bDate ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const sortEventsByLocationName = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aLocationName = a.JobRole.Location.name;
    const bLocationName = b.JobRole.Location.name;
    const sortOrderCoefficient = order === 'asc' ? 1 : -1;
    return ( aLocationName > bLocationName ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const sortEventsByJobRole = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aName = a.JobRole.name;
    const bName = b.JobRole.name;
    const sortOrderCoefficient = order === 'asc' ? 1 : -1;
    return ( aName > bName ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const eventsSortingsMap = {
  employee: sortEventsByEmployeeName,
  startTime: sortEventsByStartTime,
  location: sortEventsByLocationName,
  jobRole: sortEventsByJobRole,

};
