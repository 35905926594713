import PropTypes from 'prop-types';
import React from 'react';
import './Legend.scss';
import Unit from './Unit/Unit';

const Legends = ( { units } ) => {
  return (
    <div className='legend'>
      <ul className='caption-list'>
        {units.map( ( unit, num ) => (
          <Unit
            key={num}
            data={unit}
          />
        ) )}
      </ul>
    </div>
  );
};

Legends.propTypes = {
  units: PropTypes.array
};

export default Legends;
