import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../OperationsScreen.module.sass';
import { OperationEventBadge } from './OperationEventBadge';

const OperationBadgeComponent = ( { icon: Icon, events, employee } ) => {
  const link = useMemo( () => {
    if ( events?.length === 1 ) {
      const firstEvent = events[0];
      return `/event-listing/${firstEvent.id}`;
    }
  }, [ events ] );

  return link ? (
    <Link
      to={link}
      className={styles['badge']}
    >
      <OperationEventBadge
        events={[ events ].flat()}
        employee={employee}
      />
    </Link>
  ) : (
    <div className={styles['badge']}>
      {events ? (
        <OperationEventBadge
          events={[ events ].flat()}
          employee={employee}
        />
      ) : (
        <Icon />
      )}
    </div>
  );
};

export const OperationBadge = memo( OperationBadgeComponent );
