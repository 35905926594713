import { skipToken } from '@reduxjs/toolkit/dist/query';
import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../components/Badge';
import { InfoRows } from '../../../components/InfoRows/InfoRows';
import LinkBack from '../../../components/LinkBack';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import { useGetPresetTasksQuery } from '../../../store/apis/presetTasksApi';
import {
  formatDateString,
  formatDiff,
  formatTimeString
} from '../../../utils/dates/dates';

const Default = () => {
  const { orderId } = useParams();
  const { data: event, isLoading } = useGetJobEventQuery( orderId || skipToken );
  const { data: tasks } = useGetPresetTasksQuery();

  const status = event.status === 'unprocessed' ? 'open' : event.status;

  const task = useMemo(
    () => tasks?.filter( el => el.id === event?.Task?.PresetTaskId )?.[0],
    [ event, tasks ]
  );
  const TaskSkills = useMemo(
    () =>
      task?.Skills.filter( skill =>
        event.EventSkills.some( eventSkill => eventSkill.SkillId === skill.id )
      ),
    [ event, task ]
  );

  const startDate = formatDateString( event?.start );
  const endDate = formatDateString( event?.end );
  const diffHoursTime = formatDiff( event?.start, event?.end );
  const printHandler = () => window.print();

  if ( isLoading ) return null;

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <LinkBack title='Back to Event listing' />
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <div className='title-box mt-3'>
              <h1 className='title title-small'>{event?.JobRole?.Location?.name}</h1>
              {event.lockedAt && (
                <span className='title-badge title-badge--locked ml-md-3'>
                  {event.LastLocker?.firstName} {event.LastLocker?.lastName}
                </span>
              )}

              <span
                className={`title-badge title-badge--${
                  status === 'canceled' || status === 'failed' ? 'warning' : null
                } ml-3`}
              >
                Status: {status}
              </span>

              <span className='badge-box ml-3'>
                <Badge status={[ 'order', 'offer', 'log' ].includes( event.phase )}>
                  Order
                </Badge>
                <Badge status={[ 'offer', 'log' ].includes( event.phase )}>Offer</Badge>
                <Badge status={[ 'log' ].includes( event.phase )}>Logs</Badge>
              </span>
            </div>
            <div className='actions mt-4 mt-md-3'>
              <span
                onClick={printHandler}
                className='icon save-pdf'
              />
              <span
                onClick={printHandler}
                className='icon print'
              />
            </div>
          </div>
        </div>
      </div>
      <InfoRows
        className='mt-4'
        rows={[
          {
            items: [
              { label: 'Task', value: task?.name },
              { label: 'Location', value: event?.JobRole?.Location?.name },
              {
                label: 'Address',
                value: `${event?.JobRole?.Location?.address_Street}, ${event?.JobRole?.Location?.address_City}`
              }
            ]
          },
          {
            items: [
              {
                label: 'Date',
                value: `${startDate} ${startDate !== endDate ? `- ${endDate}` : ''}`
              },
              { label: 'Start time', value: formatTimeString( event?.start ) },
              { label: 'End time', value: formatTimeString( event?.end ) },
              { label: 'Length', value: diffHoursTime }
            ]
          },
          {
            items: [
              {
                label: 'Required skills',
                element: (
                  <div className='required-skills mt-2'>
                    {TaskSkills?.map( skill => (
                      <div
                        key={skill.id}
                        className='required-skills'
                      >
                        <div className='skill-box pr-2'>{skill.name}</div>
                      </div>
                    ) )}
                  </div>
                )
              },
              {
                label: 'Description',
                element: (
                  <div className='info-col__title info-col__title--textarea'>
                    {event.phase === 'offer'
                      ? event.JobOffer.description
                      : event.JobOrder?.description}
                  </div>
                )
              }
            ]
          }
        ]}
      />
    </>
  );
};

export default memo( Default );
