import React, { useMemo, useState } from 'react';
import { usePagination } from '../../../hooks';

import { useGetConversationsListQuery } from '../../../store/apis/chatApi';
import { sortConversationsByDate } from '../../../utils/sortings/messagesSortings';

import { Input } from '../../GlobalComponents/Elements';
import RowSecond from '../../GlobalComponents/Row';
import { ChatGroups } from '../ChatGroups';
import DialogsItem from '../DialogsItem/DialogsItem';

export const DialogsScreen = ( { title } ) => {
  const { data: conversations } = useGetConversationsListQuery();

  const [ group, setGroup ] = useState();
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const unreadConversations = useMemo( () => {
    return ( conversations || [] ).filter(
      conversation =>
        conversation.LastMessage &&
        conversation.LastReadMessageId !== conversation.LastMessage?.id
    );
  }, [ conversations ] );

  const filteredByGroup = useMemo( () => {
    const tmp = conversations || [];
    if ( group === 'all' ) return tmp;
    else return unreadConversations;
  }, [ conversations, group ] );

  const filteredConversations = useMemo( () => {
    if ( !searchTerm ) {
      return sortConversationsByDate( filteredByGroup );
    }
    const filtered = filteredByGroup.filter( conversation =>
      conversation.topic.toLowerCase().includes( searchTerm.toLowerCase() )
    );
    return sortConversationsByDate( filtered );
  }, [ filteredByGroup, searchTerm ] );

  const { visibleItems, PaginationComponent, itemsPerPage, itemOffset } = usePagination(
    filteredConversations,
    {
      perPageVariants: [],
      type: 'compact'
    }
  );

  return (
    <div className='app-content messages messagesScreen'>
      <div className='container-fluid'>
        <RowSecond marginBottom={59}>
          <h1 className='messages__title title no-margin'>{title}</h1>
          <RowSecond>
            <Input
              style={{ width: 400, marginRight: 16 }}
              value={searchTerm}
              onChange={setSearchTerm}
              modificators={{
                width: 'full'
              }}
            />
          </RowSecond>
        </RowSecond>

        <RowSecond>
          <ChatGroups
            onChange={setGroup}
            unreadCount={unreadConversations.length}
          />

          <div className='d-flex'>
            <span>
              Messages{' '}
              <b>
                {itemOffset + 1}-
                {itemOffset + itemsPerPage < filteredConversations.length
                  ? itemOffset + itemsPerPage
                  : filteredConversations.length}
              </b>{' '}
              out of {filteredConversations.length}
            </span>

            <div>{PaginationComponent}</div>
          </div>
        </RowSecond>

        <div className='messagesScreen__list'>
          {visibleItems.map( ( conversation, index ) => {
            return (
              <DialogsItem
                key={index}
                conversation={conversation}
              />
            );
          } )}
        </div>
      </div>
    </div>
  );
};
