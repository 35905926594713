import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/chatMessage-delete.svg';
import { ReactComponent as ReplyIcon } from '../../../../assets/images/chatMessage-message.svg';
import { ChatContext } from '../ChatScreen';
import './chatMessage.sass';

const ChatMessage = ( { deleteModal, message } ) => {
  const { replyToMessage, messages, scrollToMessage } = useContext( ChatContext );

  const { text, JoblinkGroupId, createdAt, ReplyToId } = message;

  const date = DateTime.fromISO( createdAt ).toFormat( 'hh:mm' );

  const onReply = () => {
    replyToMessage( message );
  };

  const repliedMesssage = useMemo( () => {
    if ( !ReplyToId ) return undefined;
    return messages.find( msg => msg.id === ReplyToId );
  }, [ ReplyToId ] );

  const repliedMessageText = useMemo( () => {
    if ( !repliedMesssage ) return;
    const text = repliedMesssage.text;
    if ( text.length > 100 ) {
      return text.slice( 0, 100 ) + '...';
    }
    return text;
  }, [ repliedMesssage ] );

  const isStaffMessage = !!JoblinkGroupId;

  return (
    <div
      className={[ 'chatMessage', isStaffMessage ? undefined : 'me' ].join( ' ' )}
      data-id={message.id}
    >
      <div className='chatMessage__top'>
        <div className='chatMessage__column'>
          <span className='chatMessage__user'>{JoblinkGroupId ? 'Joblink' : 'You'}</span>
        </div>
        <div className='chatMessage__column'>
          <div className='chatMessage__actions'>
            <ReplyIcon onClick={onReply} />
            {!isStaffMessage && <DeleteIcon onClick={deleteModal} />}
          </div>
          <span className='chatMessage__time'>{date}</span>
        </div>
      </div>
      {repliedMesssage ? (
        <div
          className='chatMessage__middle messageAnswer'
          onClick={scrollToMessage( repliedMesssage.id )}
        >
          <p className='messageAnswer__name'>
            {repliedMesssage.JoblinkGroupId ? 'Joblink' : 'You'}
          </p>
          <p className='messageAnswer__message'>{repliedMessageText}</p>
        </div>
      ) : null}
      <div className='chatMessage__bottom'>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ChatMessage;
