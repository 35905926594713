import { DateTime } from 'luxon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { ReactComponent as CommentIcon } from '../../../../assets/images/operations/comment.svg';
import { isEventsIntersecting } from '../../../../utils/dates/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';
import { HoverBoxAbandoned } from './HoverBoxAbandoned';
import { HoverBoxComment } from './HoverBoxComment';
import { HoverBoxMultiple } from './HoverBoxMultiple';

export const OperationEventBadge = ( { events, employee } ) => {
  const { acceptedEventsByEmployee } = useContext( OperationsScreenContext );

  const [ hoverBoxTop, setHoverBoxTop ] = useState( false );
  const [ intersectingEvents, setIntersectingEvents ] = useState( [] );

  const dates = useMemo( () => {
    const firstEvent = events[0];
    return {
      start: DateTime.fromISO( firstEvent.start ).toFormat( 'HH:mm' ),
      end: DateTime.fromISO( firstEvent.end ).toFormat( 'HH:mm' )
    };
  }, [ { events } ] );

  const status = useMemo( () => {
    if ( events.length > 1 ) {
      return 'multiple';
    }
    const event = events[0];
    const accepted = acceptedEventsByEmployee[employee.id] || [];
    if ( accepted.map( event => event.id ).includes( event.id ) ) {
      return 'accepted';
    }
    const date = DateTime.fromISO( event.start );
    const acceptedOnSameDate = accepted.filter( event =>
      DateTime.fromISO( event.start ).hasSame( date, 'day' )
    );
    const intersecting = acceptedOnSameDate.filter( acceptedEvent =>
      isEventsIntersecting( event, acceptedEvent )
    );
    if ( intersecting.length ) {
      setIntersectingEvents( intersecting );
      return 'abandoned';
    }
    return 'offered';
  }, [ events, acceptedEventsByEmployee ] );

  const hasComment = useMemo( () => {
    if ( events.length > 1 ) return false;
    const event = events[0];
    if ( event.phase === 'order' ) return !!event.JobOrder.description;
    if ( event.phase === 'offer' ) return !!event.JobOffer.description;
    return false;
  }, [ events ] );

  const className = useMemo( () => concat( styles['event'], styles[status] ), [ status ] );

  const badgeRef = useCallback( element => {
    if ( element ) {
      setHoverBoxTop(
        ( window.scrollY + element.getBoundingClientRect().y ) /
          document.body.offsetHeight >
          0.9
      );
    }
  }, [] );

  const hoverBox = useMemo( () => {
    if ( status === 'multiple' ) {
      const event = events[0];
      const date = DateTime.fromISO( event.start );
      return (
        <HoverBoxMultiple
          date={date}
          employee={employee}
          events={events}
          className={hoverBoxTop ? styles['top'] : undefined}
        />
      );
    } else if ( intersectingEvents?.length ) {
      const event = events[0];
      const date = DateTime.fromISO( event.start );
      return (
        <HoverBoxAbandoned
          date={date}
          events={[ event, ...intersectingEvents ]}
          crossedOut={[ event.id ]}
        />
      );
    } else if ( hasComment ) {
      const event = events[0];
      const date = DateTime.fromISO( event.start );
      return (
        <HoverBoxComment
          date={date}
          employee={employee}
          event={event}
          className={hoverBoxTop ? styles['top'] : undefined}
        />
      );
    }
  }, [ status, dates, intersectingEvents ] );

  return (
    <>
      <div
        className={className}
        ref={badgeRef}
      >
        <span>{dates.start}</span>
        <span>{dates.end}</span>
        {hoverBox}
      </div>
      {hasComment && !intersectingEvents?.length && (
        <CommentIcon className={styles['comment-icon']} />
      )}
    </>
  );
};
