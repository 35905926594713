import { DateTime, Duration } from 'luxon';
import { useContext, useMemo, useState } from 'react';
import { useGetAllEmployeesQuery } from '../../../../store/apis/employeesApi';
import { useGetJobEventsQuery } from '../../../../store/apis/eventsApi';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';
import { EmployeeRow } from './EmployeeRow';
import { JobRoleRow } from './JobRoleRow';

export const JobRoleGroup = ( { jobRole, location } ) => {
  const { weekStart, weeksCount, employeeFilter, locationFilter } = useContext(
    OperationsScreenContext
  );

  const { data: events } = useGetJobEventsQuery();

  const [ hoursByEmployee, setHoursByEmployee ] = useState( {} );

  const { data: employees } = useGetAllEmployeesQuery( {
    filters: { statuses: [ 'active' ] },
    includes: {
      AcceptedOffers: {
        start: DateTime.now().minus( { days: 180 } ).startOf( 'day' ).toJSDate(),
        end: DateTime.now().endOf( 'day' ).toJSDate()
      }
    }
  } );

  const eventsEmployees = useMemo( () => {
    if ( !events || !employees ) return [];
    let filteredEvents = events.filter( event => {
      const start = DateTime.fromISO( event.start );
      const weeksDiff = start.weekNumber - weekStart.weekNumber;
      if ( weeksDiff < 0 || weeksDiff > weeksCount - 1 ) return false;

      return (
        event.JobRoleId === jobRole.id &&
        ( event.JobOffer?.AcceptedEmployeeId || event.JobOffer?.ReceivedEmployees?.length )
      );
    } );
    if ( employeeFilter ) {
      filteredEvents = filteredEvents.filter( event => {
        const acceptedEmployeeId = event.JobOffer?.AcceptedEmployeeId;
        if ( acceptedEmployeeId === employeeFilter.id ) return true;
        const receivedEmployees = event.JobOffer?.ReceivedEmployees;
        return !!receivedEmployees?.find( employee => employee.id === employeeFilter.id );
      } );
    }
    let eventsEmployees = [];
    const addEmployee = employee => {
      if ( !eventsEmployees.find( item => item.id === employee.id ) )
        eventsEmployees.push(
          employees.find( expandedEmployee => expandedEmployee.id === employee.id )
        );
    };
    for ( const event of filteredEvents ) {
      if ( event.JobOffer.AcceptedEmployee ) addEmployee( event.JobOffer.AcceptedEmployee );
      else
        for ( const employee of event.JobOffer.ReceivedEmployees ) {
          if ( employeeFilter && employee.id !== employeeFilter.id ) continue;
          addEmployee( employee );
        }
    }
    return eventsEmployees;
  }, [ events, weekStart, jobRole, employeeFilter, weeksCount, employees ] );

  const totalHours = useMemo( () => {
    if ( !Object.values( hoursByEmployee ).length ) return;
    const total = Array( weeksCount ).fill( Duration.fromObject( { hours: 0, minutes: 0 } ) );
    for ( const durations of Object.values( hoursByEmployee ) ) {
      durations.forEach( ( duration, index ) => {
        if ( total[index] ) total[index] = total[index].plus( duration );
      } );
    }
    return total;
  }, [ hoursByEmployee, weeksCount ] );

  if ( !eventsEmployees.length || ( locationFilter && locationFilter.id !== location.id ) )
    return null;

  return (
    <>
      <JobRoleRow
        jobRole={jobRole}
        location={location}
        totalHours={totalHours}
      />
      {eventsEmployees.map( ( employee, index ) => (
        <EmployeeRow
          key={index}
          jobRoleId={jobRole.id}
          employee={employee}
          setHoursByEmployee={setHoursByEmployee}
          className={index === eventsEmployees.length - 1 ? styles['last'] : undefined}
        />
      ) )}
    </>
  );
};
