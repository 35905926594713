import { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import ErrorBoundary from '../../components/ErrorBoundary';
import Orders from '../../components/Orders';
import { CurrentEvents, PerformanceEvents } from './components';

const DashboardScreen = () => {
  const [ upcomingEventsFilter, setUpcomingEventsFilter ] = useState( {
    value: 'all',
    label: 'Show All'
  } );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title'>Dashboard</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <Card
                title='Upcoming Events'
                selectOptions={[
                  { value: 'all', label: 'Show All' },
                  { value: 'open', label: 'Open' },
                  { value: 'filled', label: 'Filled' }
                ]}
                selectFilterState={upcomingEventsFilter}
                setSelectFilterState={setUpcomingEventsFilter}
              >
                <div className='card-body d-flex flex-column align-items-center'>
                  <Orders status={upcomingEventsFilter.value} />
                </div>
                <div className='card-footer'>
                  <div className='btn-group'>
                    <Link
                      className='btn btn--light-green btn--block'
                      to='/event-listing'
                    >
                      Show all
                    </Link>
                    <Link
                      className='btn btn--light-green btn--block'
                      to='/new-order'
                    >
                      Create new
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <CurrentEvents />
            </div>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <PerformanceEvents />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default DashboardScreen;
