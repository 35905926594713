import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const UpcomingEvent = ( { event } ) => {
  const status = event.status === 'unprocessed' ? 'open' : event.status;

  const date = useMemo( () => {
    if ( event ) {
      const start = DateTime.fromISO( event.start );
      const end = DateTime.fromISO( event.end );
      return `${start.toFormat( 'dd.LL.' )} ${start.toFormat( 'HH:mm' )}-${end.toFormat(
        'HH:mm'
      )}`;
    }
  }, [ event ] );

  return (
    <li
      key={event.id}
      data-status={event.status}
    >
      <Link
        to={`/event-listing/${event.id}`}
        className='d-flex flex-column align-items-start'
      >
        <div className='d-flex justify-content-between align-items-center'>
          <span className='job-row'>
            <span
              className='job-title'
              data-has-employee={!!event.JobOffer?.AcceptedEmployee}
            >
              {event.JobOffer?.AcceptedEmployee
                ? `${event.JobOffer.AcceptedEmployee.firstName} ${event.JobOffer.AcceptedEmployee.lastName}`
                : status}
            </span>
          </span>
        </div>
        <span className='job-date'>{date}</span>
        <div className='job-role'>{event.JobRole.name}</div>
      </Link>
    </li>
  );
};
