import { Field, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import DatePickerField from '../../../components/DatePickerField';
import { JobRating } from '../../../components/JobRating/JobRating';
import { fieldErrorClass } from '../../../constants';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';

export const EditLogForm = ( { onSubmit, closeModal, initialValues } ) => {
  const { orderId } = useParams();

  const jobEvent = useGetJobEventQuery( orderId );

  if ( !jobEvent.data ) return null;

  const onCloseClick = event => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={( values, { setSubmitting } ) => {
        onSubmit( values );
        setSubmitting( false );
        closeModal();
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {( {
        errors,
        touched,
        handleSubmit,
        values,
        handleChange,
        isValid,
        isSubmitting
      } ) => (
        <form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='Customer_Comment'>Comment</label>
              <Field
                className={`form-control ${
                  errors.Customer_Comment && touched.Customer_Comment && fieldErrorClass
                }`}
                type='text'
                id='Customer_Comment'
                name='Customer_Comment'
                as='textarea'
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row mr-4 w-100'>
              <label htmlFor='Employee_Start'>Employee Start time</label>
              <DatePickerField
                name='Employee_Start'
                id='Employee_Start'
                showTimeSelect
                dateFormat='dd/MM/yyyy HH:mm'
              />
            </div>
            <div className='form-row w-100'>
              <label htmlFor='Employee_End'>Employee End time</label>
              <DatePickerField
                name='Employee_End'
                id='Employee_End'
                showTimeSelect
                dateFormat='dd/MM/yyyy HH:mm'
              />
            </div>
            <div className='form-row ml-4'>
              <label>Rating</label>
              <JobRating
                value={values.Customer_Rating}
                onChange={handleChange( 'Customer_Rating' )}
              />
            </div>
          </div>
          <div className='form-footer mt-4 mb--4'>
            <div className='form-group'>
              <div className='form-row'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </button>
              </div>
              <div className='form-row'>
                <button
                  className='btn btn--default'
                  onClick={onCloseClick}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const validationSchema = yup.object( {
  Employee_Comment: yup.string(),
  Employee_Rating: yup.string().oneOf( [ 'good', 'bad', '' ] ),
  Employee_Start: yup.date().required(),
  Employee_End: yup.date().required(),
  Employee_LunchBreak: yup.number().required(),
  Customer_Rating: yup.string().oneOf( [ 'good', 'bad', '' ] ),
  Customer_Comment: yup.string()
} );
