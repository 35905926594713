import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

export const FormController = ( {
  setSkillsOptions,
  jobRole,
  skillsOptions,
} ) => {
  const { values, setFieldValue } = useFormikContext();

  const [ initialized, setInitialized ] = useState( false );
  const [ currentTask, setCurrentTask ] = useState();

  const clearValues = useCallback(
    valuesToClear => valuesToClear.forEach( val => setFieldValue( val, '' ) ),
    [ setFieldValue ]
  );
  const clearOptions = optionsSetters =>
    optionsSetters.forEach( setOptions => setOptions( [] ) );

  const onTaskUpdate = () => {
    if ( !values.TaskId ) return;
    if ( initialized ) clearValues( [ 'EventSkills' ] );
    clearOptions( [ setSkillsOptions ] );
    const task = jobRole.Tasks?.find( t => t.id === parseInt( values.TaskId ) );
    setSkillsOptions(
      task?.Skills.map( skill => ( { value: skill.id, label: skill.name } ) ) || []
    );
    setCurrentTask( task );
  };

  const onSelectAllSkills = () => {
    if ( initialized ) {
      const skillsValue = !values.allSelected
        ? currentTask?.Skills?.map( skill => '' + skill.id )
        : [];
      setFieldValue( 'EventSkills', skillsValue || [] );
    }
  };

  const onSkillsChange = () => {
    setFieldValue(
      'allSelected',
      skillsOptions.length === values.EventSkills.length
    );
  };

  useEffect( () => {
    if ( !initialized && currentTask ) {
      setInitialized( true );
    }
  }, [ initialized, currentTask ] );

  useEffect( onTaskUpdate, [
    values.TaskId,
    jobRole,
    setSkillsOptions,
    clearValues,
  ] );
  useEffect( onSelectAllSkills, [
    values.selectAllSkills,
    currentTask,
    setFieldValue,
  ] );
  useEffect( onSkillsChange, [
    setFieldValue,
    skillsOptions,
    values.EventSkills.length,
  ] );

  return null;
};
