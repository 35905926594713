import { mainApi } from './mainApi';

export const employeesApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getMatchingEmployees: build.query( {
      query: ( { jobEvent, availability } ) => ( {
        url: '/employees',
        method: 'post',
        body: {
          filters: {
            available: {
              start: jobEvent.start,
              end: jobEvent.end,
              level: availability
            }
          },
          includes: includes( jobEvent )
        }
      } ),
      providesTags: [ 'employees' ]
    } ),
    getAllEmployees: build.query( {
      query: (
        data = {
          filters: {
            statuses: [ 'active' ]
          },
          includes: {
            Skills: true,
            Certificates: true
          }
        }
      ) => ( {
        url: '/employees',
        method: 'post',
        body: data
      } ),
      providesTags: ( res, err, arg ) =>
        err
          ? []
          : [
              {
                type: 'employees',
                id: JSON.stringify( arg )
              }
            ]
    } ),
    setEmployeeSkills: build.mutation( {
      query: ( { employeeId, skillsIds } ) => ( {
        url: `/employees/${employeeId}/skills`,
        method: 'put',
        body: {
          SkillIds: skillsIds
        }
      } ),
      invalidatesTags: [ 'employees' ]
    } ),
    updateEmployee: build.mutation( {
      query: ( { employeeId, fields } ) => ( {
        url: `/employees/${employeeId}`,
        method: 'put',
        body: fields
      } ),
      invalidatesTags: [ 'employees' ]
    } ),
    deleteCertificate: build.mutation( {
      query: id => ( {
        url: `/certificates/${id}`,
        method: 'delete'
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'employees' ] )
    } ),
    uploadCertificate: build.mutation( {
      query: ( { file, employeeId, certificateTypeId, validationDate } ) => ( {
        url: '/certificates',
        body: ( () => {
          const data = new FormData();
          data.append( 'file', file );
          data.append( 'validationDate', validationDate.toISOString() );
          data.append( 'EmployeeId', employeeId );
          data.append( 'CertificateTypeId', certificateTypeId );

          return data;
        } )(),
        method: 'post'
      } ),
      invalidatesTags: [ 'employees' ]
    } ),
    getEmployeeLeaves: build.query( {
      query: id => ( {
        url: `/employees/${id}/leaves`
      } ),
      providesTags: ( res, err, id ) =>
        err
          ? []
          : res
              .map( leave => ( { type: 'leaves', id: leave.id } ) )
              .concat( { type: 'leaves', id: `employee${id}` } )
    } ),
    createEmployeeLeave: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/employees/${id}/leaves`,
        method: 'post',
        body: fields
      } ),
      invalidatesTags: ( res, err, { id } ) => ( err ? [] : [ { type: 'leaves', id } ] )
    } ),
    resolveEvent: build.mutation( {
      query: ( { token, eventId, reopen } ) => ( {
        url: `/leaves/${token}/resolve/${eventId}`,
        params: { reopen },
        method: 'post'
      } )
    } ),
    editLeave: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/leaves/${id}`,
        method: 'put',
        body: fields
      } ),
      invalidatesTags: ( res, err, { id } ) => ( err ? [] : [ { type: 'leaves', id } ] )
    } )
  } )
} );

export const {
  useGetMatchingEmployeesQuery,
  useGetAllEmployeesQuery,
  useSetEmployeeSkillsMutation,
  useUpdateEmployeeMutation,
  useDeleteCertificateMutation,
  useUploadCertificateMutation,
  useGetEmployeeLeavesQuery,
  useCreateEmployeeLeaveMutation,
  useResolveEventMutation,
  useEditLeaveMutation
} = employeesApi;

function includes( jobEvent ) {
  return {
    AcceptedOffers: {
      start: ( () => {
        const eventStartDate = new Date( jobEvent.start );
        eventStartDate.setDate( eventStartDate.getDate() - 180 );
        return eventStartDate;
      } )(),
      end: jobEvent.end
    },
    Skills: true,
    RatedJobRole: {
      JobRoleId: jobEvent.JobRoleId
    }
  };
}
