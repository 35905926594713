import { mainApi } from './mainApi';

export const eventsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getJobEvent: build.query( {
      query: id => ( { url: `/jobEvents/${id}` } ),
      providesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'event', id: arg } ] )
    } ),
    sendOffer: build.mutation( {
      query: ( { jobEventId, employeesIds } ) => ( {
        url: `/jobEvents/${jobEventId}/offer/send`,
        body: employeesIds,
        method: 'post'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.jobEventId }, 'allEvents' ]
    } ),
    getJobEvents: build.query( {
      query: params => ( { url: '/jobEvents', params } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'allEvents' ] )
    } ),
    createJobEvent: build.mutation( {
      query: values => ( {
        url: '/jobevents/_/order',
        body: {
          start: values.startTime,
          end: values.endTime,
          TaskId: parseInt( values.task ),
          EventSkills: Array.isArray( values.eventSkills )
            ? values.eventSkills.map( el => {
                return { SkillId: parseInt( el ) };
              } )
            : [],
          JobOrder: {
            description: values.description
          }
        },
        method: 'post'
      } ),
      invalidatesTags: [ 'allEvents' ]
    } ),
    updateJobOrder: build.mutation( {
      query: ( { fields, eventId } ) => ( {
        url: `/jobEvents/${eventId}`,
        body: fields,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    updateJobOrderDescription: build.mutation( {
      query: ( { eventId, description } ) => ( {
        url: `/jobEvents/${eventId}/order`,
        body: { description },
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    updateJobOffer: build.mutation( {
      query: ( { eventId, description } ) => ( {
        url: `/jobEvents/${eventId}/offer`,
        body: { description },
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    submitJobLog: build.mutation( {
      query: ( { id, jobLog } ) => ( {
        url: `/jobEvents/${id}/jobLog/customer`,
        method: 'post',
        body: jobLog
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.id }, 'allEvents' ]
    } ),
    lockEvent: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/lock`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    unlockEvent: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/unlock`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    createOffer: build.mutation( {
      query: ( { eventId, description } ) => ( {
        url: `/jobEvents/${eventId}/offer`,
        method: 'post',
        body: { description }
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    cancelOffer: build.mutation( {
      query: eventId => {
        return {
          url: `/jobEvents/${eventId}/offer/cancel`,
          method: 'delete'
        };
      },
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    cancelOrder: build.mutation( {
      query: eventId => {
        console.log( 'order event' );
        return {
          url: `/jobEvents/${eventId}/order/cancel`,
          method: 'delete'
        };
      },
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    abortOffer: build.mutation( {
      query: eventId => ( {
        url: `/jobEvents/${eventId}/offer/abort`,
        method: 'delete'
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    reactivateOffer: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/offer/reactivate`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } )
  } )
} );

export const {
  useGetJobEventQuery,
  useSendOfferMutation,
  useGetJobEventsQuery,
  useCreateJobEventMutation,
  useUpdateJobOrderMutation,
  useUpdateJobOrderDescriptionMutation,
  useUpdateJobOfferMutation,
  useSubmitJobLogMutation,
  useLockEventMutation,
  useUnlockEventMutation,
  useCreateOfferMutation,
  useCancelOfferMutation,
  useCancelOrderMutation,
  useAbortOfferMutation,
  useReactivateOfferMutation
} = eventsApi;
