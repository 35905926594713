import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/images/alert-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/chat-close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon-green.svg';
import { useMessagesListener } from '../../../hooks';
import {
  useGetSingleConversationsQuery,
  useReadMessageMutation
} from '../../../store/apis/chatApi';
import { InputSearchSmall } from '../../GlobalComponents/Elements';
import ChatBottom from './ChatBottom/ChatBottom';
import ChatMessage from './ChatMessage/ChatMessage';
import './chatScreen.sass';

export const ChatContext = createContext( {
  replyToMessage: undefined,
  discardReply: undefined,
  replyTo: undefined,
  messages: [],
  scrollToMessage: undefined,
} );

const ChatScreen = () => {
  const { id } = useParams();
  useMessagesListener();

  const { data: conversation, isLoading } = useGetSingleConversationsQuery(
    id || skipToken
  );
  const [ readMessage ] = useReadMessageMutation();

  const [ isSearchOpen, setSearchOpen ] = useState( false );
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const [ replyTo, setReplyTo ] = useState();
  const replyToMessage = message => setReplyTo( message );
  const discardReply = () => setReplyTo( undefined );

  const chatRef = useRef( null );

  useEffect( () => {
    if ( chatRef.current ) {
      chatRef.current.scrollBy( {
        top: 10 ** 10,
      } );
    }
  }, [ conversation ] );

  const toggleSearch = value => () => setSearchOpen( value );
  const clearSearch = () => {
    setSearchOpen( false );
    setSearchTerm( '' );
  };

  const visibleMessages = useMemo( () => {
    if ( conversation ) {
      if ( isSearchOpen ) {
        return conversation.Messages.filter( message => {
          return message.text.toLowerCase().includes( searchTerm.toLowerCase() );
        } );
      } else {
        return conversation.Messages;
      }
    } else return [];
  }, [ searchTerm, isSearchOpen, conversation ] );

  useEffect( () => {
    if ( !conversation ) return;
    const lastMessage = conversation.Messages.length
      ? conversation.Messages[conversation.Messages.length - 1]
      : undefined;
    if ( lastMessage ) {
      readMessage( { conversationId: conversation.id, messageId: lastMessage.id } );
    }
  }, [ conversation ] );

  const scrollToMessage = id => () => {
    if ( chatRef.current ) {
      const messageElement = chatRef.current.querySelector( `[data-id="${id}"]` );
      if ( messageElement ) {
        chatRef.current.scrollTo( {
          top: messageElement.offsetTop - chatRef.current.offsetTop,
          behavior: 'smooth',
        } );
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <div>Loading</div>
        </>
      ) : (
        <ChatContext.Provider
          value={{
            replyToMessage,
            discardReply,
            replyTo,
            messages: conversation?.Messages,
            scrollToMessage,
          }}
        >
          <div className='chat'>
            <div className='chat__top'>
              <h4 className='chat__subject'>{conversation.topic}</h4>
              <div className='chat__actions'>
                {isSearchOpen ? (
                  <InputSearchSmall
                    onClose={clearSearch}
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                ) : (
                  <SearchIcon onClick={toggleSearch( true )} />
                )}
                <Link to='/messages'>
                  <CloseIcon />
                </Link>
              </div>

              {conversation?.unidirectional && (
                <div className='unidirectional'>
                  <AlertIcon />
                  Only Joblink staff can contribute to this conversation!
                </div>
              )}
            </div>

            <div
              className='chat__middle'
              ref={chatRef}
            >
              {visibleMessages.map( message => {
                return (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    conversation={conversation}
                  />
                );
              } )}
            </div>

            <div className='chat__bottom'>
              <ChatBottom />
            </div>
          </div>
        </ChatContext.Provider>
      )}
    </>
  );
};

export default ChatScreen;
