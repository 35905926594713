import { useMemo } from 'react';
import { concat, getEmployeeWorkingHours } from '../../utils/utils';

export const EmployeeHoverBox = ( { employee, className, jobRoleId } ) => {
  const employeeText = useMemo( () => {
    if ( employee ) {
      return `${employee.firstName} ${employee.lastName}`;
    }
  }, [ employee ] );

  const employeeWorkingHours = useMemo( () => {
    if ( employee?.AcceptedOffers )
      return getEmployeeWorkingHours( employee.AcceptedOffers, jobRoleId );
  }, [ employee, jobRoleId ] );

  return (
    <div className={concat( 'hover-box', className?.container )}>
      <div className={concat( 'header', className?.header )}>{employeeText}</div>
      <div className={concat( 'hover-event', className?.hoverEvent )}>
        <p>Clothing size</p>
        <p>{employee.clothingSize}</p>
      </div>
      <div className={concat( 'hover-event', className?.hoverEvent )}>
        <p>Working hours</p>
        <p>{employeeWorkingHours}</p>
      </div>
    </div>
  );
};
