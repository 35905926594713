import { useMemo } from 'react';
import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEvent } from './BadgeHoverBoxEvent';

export const HoverBoxComment = ( { employee, date, event, className } ) => {
  const comment = useMemo( () => {
    if ( event.phase === 'order' ) return event.JobOrder.description;
    if ( event.phase === 'offer' ) return event.JobOffer.description;
  }, [ event ] );

  return (
    <BadgeHoverBox
      date={date}
      employee={employee}
      className={className}
    >
      <BadgeHoverBoxEvent
        event={event}
        isLink={false}
      >
        <p className={styles['comment']}>{comment}</p>
      </BadgeHoverBoxEvent>
    </BadgeHoverBox>
  );
};
