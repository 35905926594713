import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEvent } from './BadgeHoverBoxEvent';

export const HoverBoxMultiple = ( { employee, date, events, className } ) => {
  return (
    <BadgeHoverBox
      date={date}
      employee={employee}
      className={className}
    >
      {events.map( event => (
        <BadgeHoverBoxEvent
          event={event}
          key={event.id}
        />
      ) )}
    </BadgeHoverBox>
  );
};
