import moment from 'moment';
import { useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useGetJobEventQuery
} from '../../../store/apis/eventsApi';
import CancelOrAbortForm from './CancelOrAbortForm';
import Default from './Default';
import { EditOrderForm } from './EditOrderForm/EditOrderForm';

const Unprocessed = () => {
  const { orderId } = useParams();

  const { data: event } = useGetJobEventQuery( orderId );

  const [ modalFormat, setModalFormat ] = useState( null );
  const [ showHistory, setShowHistory ] = useState( false );
  const [ cancelModalOpened, setCancelModalOpened ] = useState( false );
  const [ isEditModalOpened, setEditModalOpen ] = useState( false );

  const openCancelModal = () => setCancelModalOpened( true );
  const closeCancelModal = () => setCancelModalOpened( false );

  const onCancelOffer = () => {
    openCancelModal();
    setModalFormat( 'cancel' );
  };

  const toggleEditModalOpen = open => () => setEditModalOpen( open );

  const customStyles = {
    content: {
      maxWidth: 720,
    },
  };

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'>
              <div
                className='btn btn--light-green mr-12'
                onClick={toggleEditModalOpen( true )}
              >
                Edit
              </div>
              <div
                className='btn btn--light-danger'
                onClick={() => onCancelOffer()}
              >
                Cancel
              </div>
            </div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label ml-1'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( event?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={cancelModalOpened}
        ariaHideApp={false}
        contentLabel={`Cancel or ${modalFormat}`}
        style={customStyles}
      >
        <span
          className='icon-close'
          onClick={closeCancelModal}
        />
        <div className='modal-head'>
          <div className='modal-title'>Are you sure?</div>
        </div>
        <div className='modal-body'>
          <CancelOrAbortForm
            id={orderId}
            modalFormat={modalFormat}
            closeModal={closeCancelModal}
            phase={event?.phase}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isEditModalOpened}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className='modal-head'>
          <div className='modal-title'>Edit Event</div>
        </div>
        <EditOrderForm onClose={toggleEditModalOpen( false )} />
      </Modal>
    </>
  );
};

export default Unprocessed;
