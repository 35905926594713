import { useContext, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { RiArrowLeftCircleLine, RiArrowRightCircleLine } from 'react-icons/ri';
import { LabeledField } from '../../../components/LabeledField';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetLocationsQuery } from '../../../store/apis/locationsApi';
import styles from '../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../OperationsScreenContext';
import { CalendarButton } from './controls';

export const OperationsControls = ( {
  goNext,
  goPrev,
  onCalendarDateSelect,
  setWeeksCount,
  viewMode,
  setViewMode
} ) => {
  const { weeksCount, locationFilter, setLocationFilter } = useContext(
    OperationsScreenContext
  );

  const { data: locations } = useGetLocationsQuery();

  const onLocationSelect = locationId => {
    if ( locationId === 'all' ) setLocationFilter( undefined );
    setLocationFilter( locations.find( location => location.id === locationId ) );
  };

  useEffect( () => {
    if ( locations?.length === 1 ) {
      setLocationFilter( locations[0] );
    }
  }, [ locations ] );

  return (
    <div className={styles['controls']}>
      <div className={styles['buttons']}>
        <LabeledField label={'Location'}>
          <StyledSelect
            options={[ { value: 'all', label: 'All Locations' } ].concat(
              locations?.map( locations => ( {
                label: locations.name,
                value: locations.id
              } ) ) || []
            )}
            value={locationFilter?.id}
            onChange={onLocationSelect}
            containerStyle={{ height: 44 }}
            isDisabled={locations?.length === 1}
          />
        </LabeledField>
        <ReactDatePicker
          customInput={<CalendarButton />}
          onSelect={onCalendarDateSelect}
          wrapperClassName={styles['button']}
          popperClassName={styles['button']}
        />
        <RiArrowLeftCircleLine
          onClick={goPrev}
          className={styles['button']}
        />
        <RiArrowRightCircleLine
          onClick={goNext}
          className={styles['button']}
        />
        <LabeledField
          label={'View'}
          className={styles['select']}
        >
          <StyledSelect
            options={[
              { value: 2, label: 'Two Weeks' },
              { value: 3, label: 'Three Weeks' }
            ]}
            value={weeksCount}
            onChange={setWeeksCount}
            containerStyle={{ height: 44 }}
          />
        </LabeledField>
        <LabeledField
          label={'Group By'}
          className={styles['select']}
        >
          <StyledSelect
            options={[
              { value: 'employee', label: 'Employee' },
              { value: 'jobRole', label: 'Job Role' }
            ]}
            value={viewMode}
            onChange={setViewMode}
            containerStyle={{ height: 44 }}
          />
        </LabeledField>
      </div>
    </div>
  );
};
