import { useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../../components/Card';
import Legends from '../../../components/Legend';
import { Spinner } from '../../../components/Spinner';
import { useGetJobEventsPolling } from '../../../hooks';
import { eventListingActions } from '../../../store/slices';
import { PieChartBgColor } from '../../../theme';
import {
  filterEventsByStatus,
  filterJobLogInProgressEvents
} from '../../../utils/filters/eventsFilters';
import { performanceSelectOptions } from '../data';

export const PerformanceEvents = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ filter, setFilter ] = useState( performanceSelectOptions[0] );

  const { data: jobEvents, isLoading } = useGetJobEventsPolling( {
    start: filter.start,
    end: filter.end
  } );

  const filledEvents = useMemo(
    () => filterEventsByStatus( jobEvents || [], [ 'filled', 'completed' ] ),
    [ jobEvents ]
  );
  const failedEvents = useMemo(
    () => filterEventsByStatus( jobEvents || [], [ 'failed' ] ),
    [ jobEvents ]
  );
  const logsMissing = useMemo(
    () => filterJobLogInProgressEvents( jobEvents || [] ),
    [ jobEvents ]
  );

  const setEventListingFilters = ( start, end, status ) => () => {
    dispatch( eventListingActions.setStartDate( start ) );
    dispatch( eventListingActions.setEndDate( end ) );
    dispatch( eventListingActions.setStatusFilter( status ) );
  };

  const goToListingWithFilters = ( start, end, status ) => () => {
    setEventListingFilters( start, end, status )();
    history.push( '/event-listing' );
  };

  const goToProcessingWithFilters = ( start, end ) => () => {
    if ( start ) dispatch( eventListingActions.setProcessingStartDate( start ) );
    if ( end ) dispatch( eventListingActions.setProcessingEndDate( end ) );
    history.push( '/job-logs-processing' );
  };

  const performanceEventsChartData = useMemo(
    () => [
      {
        title: 'Filled',
        value: filledEvents.length,
        color: '#4ADE80',
        onClick: goToListingWithFilters( filter.start, filter.end, {
          label: 'Filled',
          value: 'filled'
        } )
      },
      {
        title: 'Accept job log',
        value: logsMissing.length,
        color: '#FACC15',
        className: 'small',
        onClick: goToProcessingWithFilters( filter.start, filter.end )
      },
      {
        title: 'Failed',
        value: failedEvents.length,
        color: '#F87171',
        onClick: goToListingWithFilters( filter.start, filter.end, {
          label: 'Failed',
          value: 'failed'
        } )
      },
      {
        title: 'Total',
        value: filledEvents.length + failedEvents.length,
        exclude: true
      }
    ],
    [ filledEvents, logsMissing, failedEvents ]
  );

  const percentage = useMemo(
    () =>
      Math.round(
        ( filledEvents.length / ( failedEvents.length + filledEvents.length ) ) * 100
      ),
    [ failedEvents, filledEvents ]
  );

  return (
    <Card
      title='Performance'
      selectFilterState={filter}
      setSelectFilterState={setFilter}
      selectOptions={performanceSelectOptions}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='card-body'>
            <div className='graphic'>
              <PieChart
                data={performanceEventsChartData.filter( item => !item.exclude )}
                lineWidth={50}
                background={PieChartBgColor}
                animate={true}
              />
              <div className='custom-chart'>
                <b className='custom-chart__count'>{percentage}%</b>
                <span className='custom-chart__desc'>Filled</span>
              </div>
            </div>
            <Legends units={performanceEventsChartData} />
          </div>
          <div className='card-footer'>
            <Link
              className='btn btn--light-green btn--block'
              to='/event-listing'
              onClick={setEventListingFilters( filter.start, filter.end )}
            >
              Show all
            </Link>
          </div>
        </>
      )}
    </Card>
  );
};
