import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';
import { EmployeeRow } from '..';
import { Spinner } from '../../../../components/Spinner';
import { useGetAllEmployeesQuery } from '../../../../store/apis/employeesApi';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';

export const EmployeeGroupLayout = () => {
  const { weekStart, costCenter, employeeFilter } = useContext( OperationsScreenContext );

  const { data: employees } = useGetAllEmployeesQuery( {
    filters: { statuses: [ 'active' ] },
    includes: {
      AcceptedOffers: {
        start: DateTime.now().minus( { days: 180 } ).startOf( 'day' ).toJSDate(),
        end: DateTime.now().endOf( 'day' ).toJSDate()
      }
    }
  } );

  const filteredEmployees = useMemo( () => {
    if ( employeeFilter ) return [ employeeFilter ];
    if ( !employees ) return [];
    if ( !costCenter || costCenter === 'all' ) return employees;
    return employees.filter( employee => employee.CostCenterId === costCenter );
  }, [ costCenter, employees, employeeFilter ] );

  const sortedEmployees = useMemo( () => {
    return [ ...filteredEmployees ].sort( ( a, b ) => {
      if ( a.lastName > b.lastName ) return 1;
      else if ( a.lastName < b.lastName ) return -1;
      else return a.firstName > b.firstName ? 1 : -1;
    } );
  }, [ filteredEmployees ] );

  return (
    sortedEmployees.map(
      ( employee, index ) => (
        // [ 4, 12, 5 ].includes( employee.id ) ? (
        <EmployeeRow
          key={index}
          employee={employee}
          week={weekStart}
        />
      )

      // ) : null
    ) || <Spinner className={styles['spinner']} />
  );
};
