import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useGetJobEventsPolling } from '../../hooks';
import {
  EmployeeGroupLayout,
  HeaderRow,
  JobRolesGroupLayout,
  OperationsControls
} from './components';
import styles from './OperationsScreen.module.sass';
import { OperationsScreenContext } from './OperationsScreenContext';

export const OperationsScreen = () => {
  const { data: events } = useGetJobEventsPolling();

  const [ eventsByDate, setEventsByDate ] = useState( {} );
  const [ currentWeekStart, setCurrentWeekStart ] = useState(
    DateTime.now().startOf( 'week' )
  );

  const [ weeksCount, setWeeksCount ] = useState( 2 );
  const [ viewMode, setViewMode ] = useState( 'jobRole' );
  const [ employeeFilter, setEmployeeFilter ] = useState( undefined );
  const [ locationFilter, setLocationFilter ] = useState( undefined );

  const goPrev = () => {
    setCurrentWeekStart( currentWeekStart.minus( { week: 1 } ) );
    setEventsByDate( {} );
  };
  const goNext = () => {
    setCurrentWeekStart( currentWeekStart.plus( { week: 1 } ) );
    setEventsByDate( {} );
  };

  const addEvent = useCallback(
    ( isoDate, event ) => {
      const day = eventsByDate[isoDate];
      if ( day ) {
        if ( !day.find( item => item.id === event.id ) ) {
          const updatedDay = [ ...day, event ];
          setEventsByDate( { ...eventsByDate, [isoDate]: updatedDay } );
        }
      } else {
        setEventsByDate( { ...eventsByDate, [isoDate]: [ event ] } );
      }
    },
    [ setEventsByDate, eventsByDate ]
  );

  const onCalendarDateSelect = date => {
    setCurrentWeekStart( DateTime.fromJSDate( date ).startOf( 'week' ) );
  };

  const changeViewMode = mode => {
    setViewMode( mode );
    setEventsByDate( {} );
  };

  const acceptedEventsByEmployee = useMemo( () => {
    if ( !events ) return [];
    const byEmployee = {};
    for ( const event of events ) {
      if ( event.JobOffer?.AcceptedEmployee ) {
        const id = event.JobOffer.AcceptedEmployee.id;
        if ( byEmployee[id] ) byEmployee[id].push( event );
        else byEmployee[id] = [ event ];
      }
    }
    return byEmployee;
  }, [ events ] );

  const receivedEventsByEmployee = useMemo( () => {
    if ( !events ) return [];
    const byEmployee = {};
    for ( const event of events ) {
      if ( event.JobOffer?.ReceivedEmployees?.length ) {
        const employees = event.JobOffer.ReceivedEmployees;
        for ( const employee of employees ) {
          const id = employee.id;
          const acceptedIds = acceptedEventsByEmployee[id]?.map( event => event.id );
          if ( acceptedIds && acceptedIds.includes( event.id ) ) continue;
          if ( byEmployee[id] ) byEmployee[id].push( event );
          else byEmployee[id] = [ event ];
        }
      }
    }
    return byEmployee;
  }, [ events ] );

  useEffect( () => {
    if ( events ) {
      const weekEvents = events.filter(
        event =>
          DateTime.fromISO( event.start ) >= currentWeekStart &&
          DateTime.fromISO( event.end ) <= currentWeekStart.plus( { weeks: weeksCount } )
      );
      for ( const event of weekEvents ) {
        const isoDate = DateTime.fromISO( event.start ).toISODate();
        addEvent( isoDate, event );
      }
    }
  }, [ addEvent, events ] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h1 className='title'>Operations</h1>
            </div>
          </div>
          <OperationsScreenContext.Provider
            value={{
              eventsByDate,
              addEvent,
              weekStart: currentWeekStart,
              weeksCount,
              employeeFilter,
              setEmployeeFilter,
              locationFilter,
              setLocationFilter,
              acceptedEventsByEmployee,
              receivedEventsByEmployee
            }}
          >
            <OperationsControls
              goNext={goNext}
              goPrev={goPrev}
              setWeeksCount={setWeeksCount}
              viewMode={viewMode}
              setViewMode={changeViewMode}
              onCalendarDateSelect={onCalendarDateSelect}
            />

            <div
              className={styles['table']}
              data-weeks={weeksCount}
            >
              <HeaderRow week={currentWeekStart} />
              {viewMode === 'employee' ? (
                <EmployeeGroupLayout />
              ) : (
                <JobRolesGroupLayout />
              )}
            </div>
          </OperationsScreenContext.Provider>
        </div>
      </div>
    </ErrorBoundary>
  );
};
