import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DateField } from './DateField';

export const StartTimeField = () => {
  const location = useLocation();

  const { setFieldValue } = useFormikContext();

  useEffect( () => {
    if ( location.state?.date ) {
      const dateTime = DateTime.fromJSDate( location.state.date );
      const now = DateTime.now();
      setFieldValue(
        'startTime',
        DateTime.fromObject( {
          ...dateTime.toObject(),
          minute: now.minute > 30 ? 0 : 30,
          hour: now.minute > 30 ? now.hour + 1 : now.hour,
          second: 0,
          millisecond: 0
        } ).toJSDate()
      );
    }
  }, [ location.state ] );

  return (
    <DateField
      name='startTime'
      label='Start Time'
      filterTime={() => time => {
        return time > new Date();
      }}
      filterDate={() => date => {
        const now = new Date();
        return (
          date.getDate() >= now.getDate() &&
          date.getMonth() >= now.getMonth() &&
          date.getFullYear() >= now.getFullYear()
        );
      }}
    />
  );
};
