import { DateTime } from 'luxon';
import moment from 'moment';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PdfIcon } from '../../assets/images/pdf-file.svg';
import ErrorBoundary from '../../components/ErrorBoundary';
import LinkBack from '../../components/LinkBack';
import { useGetJobEventsPolling } from '../../hooks';
import { usePagination } from '../../hooks/usePagination';
import { useSubmitJobLogMutation } from '../../store/apis/eventsApi';
import { eventListingActions } from '../../store/slices/eventListingFiltersSlice';
import { filterJobLogInProgressEvents } from '../../utils/filters/eventsFilters';
import { eventsSortingsMap } from '../../utils/sortings/eventsSortings';
import { theadData } from './data';

const getJobLogData = event =>
  event
    ? {
        Employee_Comment: event.JobLog.Employee_Comment,
        Employee_Rating: event.JobLog.Employee_Rating,
        Employee_Start: event.JobLog.Employee_Start,
        Employee_End: event.JobLog.Employee_End,
        Employee_LunchBreak: event.JobLog.Employee_LunchBreak,
        Customer_Rating: event.JobLog.Customer_Rating,
        Customer_Comment: event.JobLog.Customer_Comment
      }
    : undefined;

const JobLogsProcessingScreen = ( { title } ) => {
  const [ selectedEvents, setSelectedEvents ] = useState( [] );
  const [ selectAll, setSelectAll ] = useState( false );

  const {
    processingSortBy,
    processingSortOrder,
    processingStartDate,
    processingEndDate
  } = useSelector( state => state.filters );
  const dispatch = useDispatch();

  const events = useGetJobEventsPolling();
  const [ submitJobLog ] = useSubmitJobLogMutation();

  const logEvents = useMemo( () => {
    if ( !events.data ) return [];
    return filterJobLogInProgressEvents( events.data );
  }, [ events.data ] );

  const filteredLogEvents = useMemo( () => {
    let result = logEvents;
    if ( processingStartDate )
      result = result.filter( event => new Date( event.start ) > processingStartDate );
    if ( processingEndDate )
      result = result.filter( event => new Date( event.end ) < processingEndDate );

    setSelectedEvents( [] );

    return result;
  }, [ logEvents, processingStartDate, processingEndDate ] );

  const sortedLogEvents = useMemo( () => {
    if ( processingSortBy ) {
      return eventsSortingsMap[processingSortBy]( filteredLogEvents, processingSortOrder );
    }
    return filteredLogEvents;
  }, [ filteredLogEvents, processingSortBy, processingSortOrder ] );

  const { visibleItems, PaginationComponent } = usePagination( sortedLogEvents );

  const onSelect = selected => e => {
    setSelectedEvents(
      e.target.checked
        ? selectedEvents.concat( selected )
        : selectedEvents.filter( event => event.id !== selected.id )
    );
    setSelectAll( false );
  };

  const onSelectAll = e => {
    setSelectAll( e.target.checked );
    setSelectedEvents( e.target.checked ? filteredLogEvents : [] );
  };

  const toggleSorting = shouldSortBy => () => {
    const sortByChanged =
      processingSortBy === shouldSortBy
        ? processingSortOrder === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch( eventListingActions.setProcessingSortOrder( sortByChanged ) );
    dispatch( eventListingActions.setProcessingSortBy( shouldSortBy ) );
  };

  const onAcceptSelected = () => {
    selectedEvents.forEach( event => {
      submitJobLog( {
        id: event.id,
        jobLog: getJobLogData( event ),
        decision: 'accept',
        comment: ''
      } );
    } );
  };

  const onDateChange = action => date => dispatch( action( date ) );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <LinkBack title='Back to Event listing' />
              <h1 className='title mt-3'>
                {title} ({filteredLogEvents.length})
              </h1>
            </div>
            <div className='col-12 mb-4'>
              <div className='filters'>
                <div className='filters__body align-items-end flex-wrap'>
                  <button
                    className='btn btn--green btn--small mr-3'
                    onClick={onAcceptSelected}
                    disabled={!selectedEvents.length}
                  >
                    Accept selected
                  </button>
                  <div className='form-group mt-md-3 mt-xl-0'>
                    <div className='dates__item-picker'>
                      <p>Start time</p>
                      <ReactDatePicker
                        id='start-time-event'
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selected={processingStartDate}
                        onChange={onDateChange(
                          eventListingActions.setProcessingStartDate
                        )}
                        locale='enGb'
                        autoComplete='off'
                      />
                    </div>
                    <div className='dates__item-picker'>
                      <p>End time</p>
                      <ReactDatePicker
                        id='end-time-event'
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selected={processingEndDate}
                        onChange={onDateChange( eventListingActions.setProcessingEndDate )}
                        filterDate={time => {
                          return processingStartDate ? time > processingStartDate : true;
                        }}
                        locale='enGb'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <table className='table compact'>
                <thead>
                  <tr>
                    <th className='table-checkbox'>
                      <label className='custom-checkbox'>
                        <input
                          type='checkbox'
                          onChange={onSelectAll}
                          checked={selectAll}
                        />
                        <span />
                      </label>
                    </th>
                    <th
                      className='sortable'
                      onClick={toggleSorting( 'employee' )}
                      data-sort={
                        processingSortBy === 'employee' ? processingSortOrder : undefined
                      }
                    >
                      Employee
                    </th>
                    <th
                      className='sortable'
                      onClick={toggleSorting( 'startTime' )}
                      data-sort={
                        processingSortBy === 'startTime' ? processingSortOrder : undefined
                      }
                    >
                      Start time
                    </th>
                    <th>Hours</th>
                    <th>Customer</th>
                    <th
                      className='sortable'
                      onClick={toggleSorting( 'location' )}
                      data-sort={
                        processingSortBy === 'location' ? processingSortOrder : undefined
                      }
                    >
                      Location
                    </th>
                    <th
                      className='sortable'
                      onClick={toggleSorting( 'jobRole' )}
                      data-sort={
                        processingSortBy === 'jobRole' ? processingSortOrder : undefined
                      }
                    >
                      Job role
                    </th>
                    <th style={{ width: 80 }}>Comments</th>
                    <th>Documents</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleItems.map( event => (
                    <tr key={event.id}>
                      <td className='table-checkbox'>
                        <label className='custom-checkbox'>
                          <input
                            type='checkbox'
                            onChange={onSelect( event )}
                            checked={
                              !!selectedEvents.find( selected => selected.id === event.id )
                            }
                          />
                          <span />
                        </label>
                      </td>
                      <td className='link-in'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {`${event.JobOffer.AcceptedEmployee.firstName} 
                                                    ${event.JobOffer.AcceptedEmployee.lastName}`}
                        </NavLink>
                      </td>
                      <td className='weight-600 link-in text-center'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {DateTime.fromISO( event.start ).toFormat( 'dd.LL - HH:mm' )}
                        </NavLink>
                      </td>
                      <td className='link-in text-center'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {moment( event.end ).diff( event.start, 'hours' )}
                        </NavLink>
                      </td>
                      <td className='link-in'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {event.JobRole.Location.CompanyAccount.name}
                        </NavLink>
                      </td>
                      <td className='link-in'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {event.JobRole.Location.name}
                        </NavLink>
                      </td>
                      <td className='link-in'>
                        <NavLink to={`event-listing/${event.id}`}>
                          {event.JobRole.name}
                        </NavLink>
                      </td>
                      <td className='link-in'>
                        <NavLink
                          to={`event-listing/${event.id}`}
                          style={{ justifyContent: 'center' }}
                        >
                          {event.JobLog.Customer_Comment && '!'}
                        </NavLink>
                      </td>
                      <td className='d-flex justify-content-center'>
                        <a href='#'>
                          <PdfIcon />
                        </a>
                      </td>
                    </tr>
                  ) )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={theadData.length}>{PaginationComponent}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default JobLogsProcessingScreen;
