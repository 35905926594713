import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetLocationQuery } from '../../../../store/apis/locationsApi';
import { concat } from '../../../../utils/utils';

export const JobRoleField = ( { className } ) => {
  const navLocation = useLocation();

  const { values, handleChange, setFieldValue } = useFormikContext();

  const locationId = values.location;

  const { data: location } = useGetLocationQuery( locationId || skipToken );

  const options = useMemo( () => {
    if ( !location ) return [];
    return location.JobRoles.map( jobRole => ( {
      label: jobRole.name,
      value: jobRole.id
    } ) );
  }, [ location ] );

  useEffect( () => {
    if ( navLocation.state?.jobRoleId ) {
      setFieldValue( 'jobRole', navLocation.state.jobRoleId );
    }
  }, [ navLocation.state ] );

  return (
    <div className={concat( 'form-row', 'w-100', className )}>
      <div className='select-box'>
        <label htmlFor='jobRole'>Job Role</label>
        <Field
          as='select'
          id={'jobRole'}
          name={'jobRole'}
          className='form-control'
          value={values['jobRole']}
          onChange={handleChange}
          disabled={!options || options.length === 0}
        >
          <option
            value=''
            label={'Select Job Role'}
            disabled
            hidden
          />
          {options?.map( option => (
            <option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ) )}
        </Field>
      </div>
    </div>
  );
};
