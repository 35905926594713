import { useGetJobEventQuery, useGetJobEventsQuery } from '../store/apis/eventsApi';

const pollingInterval = 5000;

export const useGetJobEventsPolling = ( params = null ) => {
  const events = useGetJobEventsQuery( params, { pollingInterval } );
  return events;
};

export const useGetJobEventPolling = id => {
  const event = useGetJobEventQuery( id, { pollingInterval } );
  return event;
};
