import { useCallback, useMemo } from 'react';

export const InfoColumns = ( {
  columns = [],
  className,
  wrapperClassName,
  disableShadowBox,
  disableWrapper,
  boxClassName = 'mb-1',
  children,
} ) => {
  const wrapperElementClassName = useMemo( () => {
    const names = [ 'col-12' ];
    if ( wrapperClassName ) names.push( wrapperClassName );
    return names.join( ' ' );
  }, [ wrapperClassName ] );

  const boxElementClassName = useMemo( () => {
    const names = [ 'white-shadow-box' ];
    if ( boxClassName ) names.push( boxClassName );
    return names.join( ' ' );
  }, [ boxClassName, disableShadowBox ] );

  const elementClassName = useMemo( () => {
    const names = [ 'info-cols' ];
    if ( className ) names.push( className );
    return names.join( ' ' );
  }, [ className ] );

  const Content = useCallback(
    () => (
      <div className={elementClassName}>
        {columns.map( ( col, index ) => (
          <div
            key={index}
            className={[ 'info-col', col.className ].join( ' ' )}
          >
            {col.label && <span className='info-col__label'>{col.label}</span>}
            {col.element || <strong className='info-col__title'>{col.value}</strong>}
            {col.children}
          </div>
        ) )}
        {children}
      </div>
    ),
    [ columns, elementClassName, children ]
  );

  const WrapperContent = useCallback(
    () =>
      !disableShadowBox ? (
        <div className={boxElementClassName}>
          <Content />
        </div>
      ) : (
        <Content />
      ),
    [ disableShadowBox, boxElementClassName, Content ]
  );

  return disableWrapper ? (
    <WrapperContent />
  ) : (
    <div className={wrapperElementClassName}>
      <WrapperContent />
    </div>
  );
};
