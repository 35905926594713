import { DateTime } from 'luxon';

export const filterFutureEvents = events =>
  events.filter( event => DateTime.fromISO( event.start ) > DateTime.now() );

export const filterThisWeekComingEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'week' ) && eventStart > now;
  } );

export const filterThisMonthComingEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'month' ) && eventStart > now;
  } );

export const filterPastEvents = events =>
  events.filter( event => DateTime.fromISO( event.start ) < DateTime.now() );

export const filterThisMonthPastEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'month' ) && eventStart < now;
  } );

export const filterLastMonthEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.month === now.month - 1 && eventStart.year === now.year;
  } );

export const filterLastThreeMonthsEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const filterStart = DateTime.now().minus( { month: 4 } ).startOf( 'month' );
    const filterEnd = DateTime.now().minus( { month: 1 } ).endOf( 'month' );
    return eventStart >= filterStart && eventStart <= filterEnd;
  } );

export const filterCurrentYearPastEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart < now && eventStart.hasSame( now, 'year' );
  } );

export const filterEventsByStatus = ( events, statuses ) =>
  events.filter( event => statuses.includes( event.status ) );

export const filterExceptEventByStatus = ( events, status ) =>
  events.filter( event => event.status !== status );

export const filterJobLogMissingEvents = events =>
  events.filter( event => event.phase === 'log' && event.JobLog.phase === 'Employee' );

export const filterJobLogInProgressEvents = events =>
  events.filter( event => event.phase === 'log' && event.JobLog.phase === 'CustomerUser' );

export const filterFilledEventsForChart = events =>
  events.filter( event => [ 'completed', 'filled' ].includes( event.status ) );

export const filterEventsForPerformance = events =>
  events.filter( event => [ 'completed', 'filled', 'failed' ].includes( event.status ) );

export const filterByJobLogPhase = phase => events =>
  events.filter( event => event.JobLog.phase === phase );

export const filterCompletedLogs = events =>
  events.filter(
    event =>
      event.JobLog.phase === 'finalized' && event.JobLog.Joblink_Decision === 'accept'
  );
export const filterFailedLogs = events =>
  events.filter(
    event =>
      event.JobLog.phase === 'finalized' && event.JobLog.Joblink_Decision !== 'accept'
  );

export const filterEventsByJobRole = ( events, jobRoleName ) =>
  events.filter( event =>
    event.JobRole.name.toLowerCase().includes( jobRoleName.toLowerCase() )
  );

export const filterEventsByLocation = ( events, locationName ) => {
  return events.filter( event =>
    event.JobRole.Location.name.toLowerCase().includes( locationName.toLowerCase() )
  );
};

export const filterEventsByEmployee = ( events, employeeName ) => {
  return events.filter( event => {
    const receivedEmployees = event.JobOffer?.ReceivedEmployees;
    if ( !receivedEmployees ) return false;
    return !!receivedEmployees.find(
      employee =>
        employee.firstName.toLowerCase().includes( employeeName.toLowerCase() ) ||
        employee.lastName.toLowerCase().includes( employeeName.toLowerCase() )
    );
  } );
};

export const filterEventsBetweenDates = ( events, dayStart, endDay ) => {
  const filterResult = events.filter( event => {
    if ( endDay ) {
      return (
        DateTime.fromISO( event.start ) >= DateTime.fromJSDate( dayStart ).startOf( 'day' ) &&
        DateTime.fromJSDate( endDay ).endOf( 'day' ) >= DateTime.fromISO( event.end )
      );
    }
    return DateTime.fromISO( event.start ) > DateTime.fromJSDate( dayStart ).startOf( 'day' );
  } );

  return filterResult;
};

export const jobLogFilterValuesMap = {
  employee: filterByJobLogPhase( 'Employee' ),
  customer: filterByJobLogPhase( 'CustomerUser' ),
  joblink: filterByJobLogPhase( 'JoblinkUser' ),
  completed: filterCompletedLogs,
  failed: filterFailedLogs,
  filterBetweenDate: filterEventsBetweenDates
};
