import moment from 'moment';
import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import Default from './Default';

const Open = () => {
  const { orderId } = useParams();
  const { data: order } = useGetJobEventQuery( orderId );

  const [ showHistory, setShowHistory ] = useState( false );
  const jobOffer = order?.JobOffer;

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( order?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo( Open );
