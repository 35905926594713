import React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as CloseIcon } from '../../assets/images/icon-close.svg';
import { SearchPanel } from '../../components/SearchPanel';
import {
  setEndDate,
  setStartDate,
  setStatusFilter
} from '../../store/slices/eventListingFiltersSlice';
import { selectTheme } from '../../theme';
import { concat } from '../../utils';
import { filterOptions } from './data';
import styles from './EventListing.module.scss';

export const EventListingControls = ( { searchTerm, setSearchTerm } ) => {
  const dispatch = useDispatch();
  const { statusFilter, startDate, endDate } = useSelector( state => state.filters );

  const onStatusFilterChange = selectedOption => {
    dispatch( setStatusFilter( selectedOption ) );
  };

  const onStartDateChange = date => {
    dispatch( setStartDate( date ) );
  };
  const onEndDateChange = date => {
    dispatch( setEndDate( date ) );
  };

  return (
    <>
      <div className='col-12 col-lg-6'>
        <div className='btn-group justify-content-md-end'>
          <NavLink
            className='btn btn--green mr-2'
            to='/new-order'
          >
            Create Job Order
          </NavLink>
          <NavLink
            className='btn btn--light-green'
            to='/job-logs-processing'
          >
            Job logs processing
          </NavLink>
        </div>
      </div>
      <div className='col-12 mt-4 mt-lg-0 mb-4 mb-md-2 mb-lg-4'>
        <div className='filters d-md-flex align-items-end'>
          <div className='filters__body'>
            <div className='select-box'>
              <label>
                <span>Status</span>
                <Select
                  value={statusFilter}
                  onChange={onStatusFilterChange}
                  options={filterOptions}
                  theme={selectTheme}
                />
              </label>
            </div>

            <div className='dates__item-picker'>
              <p>Start time</p>
              <DatePicker
                name='startTime'
                id='start-time-event'
                dateFormat='dd/MM/yyyy'
                className='form-control'
                selected={startDate}
                onChange={onStartDateChange}
                locale='enGb'
                autoComplete='off'
              />
            </div>
            <div className={concat( 'dates__item-picker', styles['datepicker'] )}>
              <p>End time</p>
              <DatePicker
                name='endTime'
                id='end-time-event'
                dateFormat='dd/MM/yyyy'
                className='form-control'
                selected={endDate}
                onChange={onEndDateChange}
                filterDate={time => {
                  return time > startDate;
                }}
                locale='enGb'
                autoComplete='off'
              />
              {endDate && (
                <CloseIcon
                  className={styles['close']}
                  onClick={onEndDateChange.bind( null, undefined )}
                />
              )}
            </div>
          </div>

          <div className='filter-group ml-3'>
            <div className='search'>
              <SearchPanel
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder='Search...'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
