import { DateTime } from 'luxon';
import {
  filterCurrentYearPastEvents,
  filterFutureEvents,
  filterLastMonthEvents,
  filterLastThreeMonthsEvents,
  filterThisMonthComingEvents,
  filterThisMonthPastEvents,
  filterThisWeekComingEvents
} from '../../utils/filters/eventsFilters';

export const currentEventsSelectOptions = [
  {
    value: 'all_time',
    label: 'All time',
    filter: filterFutureEvents,
    start: DateTime.now().toJSDate(),
    end: undefined
  },
  {
    value: 'last_week',
    label: 'This Week',
    filter: filterThisWeekComingEvents,
    start: DateTime.now().toJSDate(),
    end: DateTime.now().endOf( 'week' ).toJSDate()
  },
  {
    value: 'last_month',
    label: 'This Month',
    filter: filterThisMonthComingEvents,
    start: DateTime.now().toJSDate(),
    end: DateTime.now().endOf( 'month' ).toJSDate()
  }
];

export const performanceSelectOptions = [
  {
    value: 'current_month',
    label: 'Current month',
    filter: filterThisMonthPastEvents,
    start: DateTime.now().startOf( 'month' ).toJSDate(),
    end: DateTime.now().toJSDate()
  },
  {
    value: 'last_month',
    label: 'Last month',
    filter: filterLastMonthEvents,
    start: DateTime.now().minus( { month: 1 } ).startOf( 'month' ).toJSDate(),
    end: DateTime.now().minus( { month: 1 } ).endOf( 'month' ).toJSDate()
  },
  {
    value: 'last_three_months',
    label: 'Last three months',
    filter: filterLastThreeMonthsEvents,
    start: DateTime.now().minus( { month: 3 } ).startOf( 'month' ).toJSDate(),
    end: DateTime.now().minus( { month: 1 } ).endOf( 'month' ).toJSDate()
  },
  {
    value: 'current_year',
    label: 'Current year',
    filter: filterCurrentYearPastEvents,
    start: DateTime.now().startOf( 'year' ).toJSDate(),
    end: DateTime.now().toJSDate()
  }
];
