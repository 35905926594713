export const selectTheme = theme => ( {
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: 'rgba(220, 252, 231, .7)',
    primary: '#22C55E',
  },
} );

export const selectStyles = {
  control: base => ( {
    ...base,
    minHeight: 46,
  } ),
  container: base => ( {
    ...base,
    width: 200,
  } ),
};

export const PieChartBgColor = '#eee';
