import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePickerField from '../../../../components/DatePickerField/DatePickerField';

export const DateField = ( { name, label, filterTime, filterDate } ) => {
  const location = useLocation();

  const { values, setFieldValue } = useFormikContext();

  useEffect( () => {
    if ( location.state?.[name] ) {
      setFieldValue( name, location.state[name] );
    }
  }, [ location.state ] );

  return (
    <div className='form-row w-100'>
      <label htmlFor={name}>{label}</label>
      <DatePickerField
        name={name}
        id={name}
        filterTime={filterTime && filterTime( values )}
        filterDate={filterDate && filterDate( values )}
        showTimeSelect
        dateFormat='dd/MM/yyyy HH:mm'
      />
    </div>
  );
};
