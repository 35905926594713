import { useContext, useMemo } from 'react';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { OperationsScreenContext } from '../../OperationsScreenContext';
import { EventsCountBadge } from '../badges';

export const JobRoleRow = ( { jobRole, location, totalHours } ) => {
  const { weeksCount, weekStart, eventsByDate } = useContext( OperationsScreenContext );

  const tiles = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, index ) => ( {
            content: ( () => {
              const date = weekStart.plus( { days: index, weeks: weekIndex } );

              return (
                <EventsCountBadge
                  events={
                    eventsByDate[date.toISODate()]?.filter(
                      event =>
                        event.JobRoleId === jobRole.id &&
                        [ 'open', 'unprocessed' ].includes( event.status )
                    ) || []
                  }
                  date={date}
                  jobRole={jobRole}
                  location={location}
                />
              );
            } )()
          } ) ),
        {
          content: totalHours ? totalHours[weekIndex]?.toFormat( 'hh:mm' ) : undefined,
          className: styles['tile-total']
        }
      ] );
  }, [ weeksCount, totalHours, eventsByDate, jobRole, weekStart ] );

  return (
    <>
      <div className={concat( styles['tile'], styles['tile-big'], styles['location'] )}>
        {location.name} - {jobRole.name} {jobRole.id}
      </div>
      {tiles.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['tile'], styles['location'], day?.className )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
